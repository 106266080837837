import { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useAppSelector } from '../../../utils/hooks'
import { selectSelectedTag } from '../../../reducers/TagReducer'
import { Divider, Form, FormInstance, Input, InputNumber } from 'antd'
import { numberRule, requiredRule, stringRule } from '../../../utils/rules'

interface Props {
  form: FormInstance
}

const Update = ({ form }: Props) => {
  const intl = useIntl()
  const item = useAppSelector(selectSelectedTag())

  useEffect(() => {
    form.setFieldsValue(item)
  }, [item])
  return (
    <Form form={form} layout="vertical">
      <Form.Item
        name="id"
        style={{ display: 'none' }}
        rules={[requiredRule(intl)]}
      >
        <Input type="hidden" />
      </Form.Item>
      <Form.Item
        name="priority"
        label="Priority"
        rules={[requiredRule(intl), numberRule(intl)]}
      >
        <InputNumber min={1} step={1} />
      </Form.Item>
      <Form.Item
        name={['fr', 'name']}
        label="🇫🇷 Nom"
        rules={[requiredRule(intl), stringRule(intl)]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={['en', 'name']}
        label="🇬🇧 Name"
        rules={[requiredRule(intl), stringRule(intl)]}
      >
        <Input />
      </Form.Item>
      <Divider />
    </Form>
  )
}

export default Update
