import {
  MetaData,
  QuickAddAction,
  QuickAddType,
} from '../modules/quick-add/Models'
import { createSlice } from '@reduxjs/toolkit'

type State = {
  isOpen: boolean
  entity: QuickAddType | undefined
  action: QuickAddAction
  metaDatas?: MetaData[]
}

const initialState: State = {
  isOpen: false,
  entity: undefined,
  action: 'view',
  metaDatas: [],
}

const slice = createSlice({
  name: 'quickadd',
  initialState,
  reducers: {
    // OPEN
    open(state) {
      state.isOpen = true
    },
    close(state) {
      state.isOpen = false
    },
    setEntity(
      state,
      action: {
        payload: {
          entity: QuickAddType
          action: QuickAddAction
          metaDatas?: MetaData[]
        }
      }
    ) {
      state.entity = action.payload.entity
      state.action = action.payload.action
      state.metaDatas = action.payload.metaDatas
      state.isOpen = true
    },
  },
})

// Reducer
export default slice.reducer

export const { open, close, setEntity } = slice.actions
