import { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { QuickAddType } from '../Models'
import { useAppSelector } from '../../../utils/hooks'
import { requiredRule, stringRule, urlRule } from '../../../utils/rules'
import { Col, Divider, Form, FormInstance, Input, Row, Select } from 'antd'
import { SBAsyncSelect } from '../../../components/select/SBAsyncSelect'
import { Menu } from '../../../components/Menu/Menu'

interface Props {
  form: FormInstance
  poiId?: number
}

const Create = ({ form, poiId }: Props) => {
  const intl = useIntl()

  const { isOpen: quickAddIsOpen } = useAppSelector((state) => state.quickadd)

  useEffect(() => {
    if (!quickAddIsOpen) {
      // We want to trigger only the reset
      // when the QuickAdd opens
      return
    }

    form.setFieldValue('poi', poiId)
  }, [quickAddIsOpen])

  return (
    <Form form={form} layout="vertical">
      {!poiId && (
        <SBAsyncSelect
          type={QuickAddType.POI}
          name={'poi'}
          label={'POI'}
          rules={[requiredRule(intl)]}
        />
      )}
      {poiId && (
        <Form.Item
          name="poi"
          style={{ display: 'none' }}
          rules={[requiredRule(intl)]}
        >
          <Input type="hidden" />
        </Form.Item>
      )}

      <Form.Item
        name={['fr', 'label']}
        label={'🇫🇷 Label'}
        rules={[requiredRule(intl), stringRule(intl)]}
      >
        <Input />
      </Form.Item>
      <Menu.LinkFormItem lang="fr" />

      <Divider />
      <Form.Item
        name={['en', 'label']}
        label={'🇬🇧 Label'}
        rules={[requiredRule(intl), stringRule(intl)]}
      >
        <Input />
      </Form.Item>
      <Menu.LinkFormItem lang="en" />
      <Divider />
    </Form>
  )
}

export default Create
