import { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Divider, Form, FormInstance, Input, Select } from 'antd'
import { useAppSelector } from '../../../utils/hooks'
import { requiredRule, stringRule, urlRule } from '../../../utils/rules'
import { selectSelectedMenu } from '../../../reducers/MenuReducer'
import { Menu } from '../../../components/Menu/Menu'

interface Props {
  form: FormInstance
}

const Update = ({ form }: Props) => {
  const intl = useIntl()
  const item = useAppSelector(selectSelectedMenu())

  useEffect(() => {
    form.setFieldsValue(item)
  }, [item])

  return (
    <Form form={form} layout="vertical">
      <Form.Item
        name="id"
        style={{ display: 'none' }}
        rules={[requiredRule(intl)]}
      >
        <Input type="hidden" />
      </Form.Item>
      <Form.Item
        name={['fr', 'label']}
        label={'🇫🇷 Label'}
        rules={[requiredRule(intl), stringRule(intl)]}
      >
        <Input />
      </Form.Item>
      <Menu.LinkFormItem lang="fr" initialValue={item?.fr} />

      <Divider />
      <Form.Item
        name={['en', 'label']}
        label={'🇬🇧 Label'}
        rules={[requiredRule(intl), stringRule(intl)]}
      >
        <Input />
      </Form.Item>
      <Menu.LinkFormItem lang="en" initialValue={item?.en} />
      <Divider />
    </Form>
  )
}

export default Update
