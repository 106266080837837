import { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Divider, Form, FormInstance, Input, Select } from 'antd'
import { useAppSelector } from '../../../utils/hooks'
import { requiredRule, stringRule } from '../../../utils/rules'
import { selectSelectedAlert } from '../../../reducers/AlertReducer'
import TextEditor from '../../../components/utils/TextEditor'
import { alertOptions } from '../../../models/Alert'

interface Props {
  form: FormInstance
}

const Update = ({ form }: Props) => {
  const intl = useIntl()
  const item = useAppSelector(selectSelectedAlert())

  useEffect(() => {
    form.setFieldsValue(item)
  }, [item])

  return (
    <Form form={form} layout="vertical">
      <Form.Item
        name="id"
        style={{ display: 'none' }}
        rules={[requiredRule(intl)]}
      >
        <Input type="hidden" />
      </Form.Item>
      <Form.Item
        name="type"
        label={'Type'}
        rules={[requiredRule(intl), stringRule(intl)]}
      >
        <Select style={{ width: 120 }} options={alertOptions} />
      </Form.Item>
      <Divider />
      <Form.Item
        name={['fr', 'title']}
        label={'🇫🇷 Titre'}
        rules={[stringRule(intl)]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={['fr', 'content']}
        label={'🇫🇷 Contenu'}
        rules={[requiredRule(intl), stringRule(intl)]}
      >
        <TextEditor />
      </Form.Item>
      <Divider />
      <Form.Item
        name={['en', 'title']}
        label={'🇬🇧 Title'}
        rules={[stringRule(intl)]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={['en', 'content']}
        label={'🇬🇧 Content'}
        rules={[requiredRule(intl), stringRule(intl)]}
      >
        <TextEditor />
      </Form.Item>
      <Divider />
    </Form>
  )
}

export default Update
