export enum QuickAddType {
  user = 'user',
  POI = 'POI',
  tag = 'tag',
  category = 'category',
  alert = 'alert',
  menu = 'menu',
}

export interface QuickAddButtonContent {
  title: string
  icon: string
  type: QuickAddType
  enabled: boolean
}

export interface StepContent {
  number: string
  title: string
  subtitle: string
  class?: string
  value: string
}

export type QuickAddAction = 'create' | 'update' | 'view' | 'delete'

export interface QuickAddFormProps {}
export interface QuickAddFormInterface {
  handleCreate: () => Promise<boolean>
  handleUpdate: () => Promise<boolean>
  handleDelete: () => Promise<boolean>
  handleReset: () => void
}

export enum MetaDataKey {
  poiId,
}
export interface MetaData {
  key: MetaDataKey
  value: any | null | undefined
}
