import { Alert, alertTypes } from '../../models/Alert'
import { useAppSelector } from '../../utils/hooks'
import { Card } from 'antd'
import { Actions } from '../../modules/quick-add/Actions'
import { QuickAddType } from '../../modules/quick-add/Models'
import { selectAlertById } from '../../reducers/AlertReducer'
import { AlertContent } from './AlertContent'

interface Props {
  id?: number
  model?: Alert
}

export const AlertAlert = ({ id, model }: Props) => {
  const alert = model ? model : useAppSelector(selectAlertById(id ?? 0))

  return alert ? (
    <Card
      style={{
        background: alertTypes[alert.type],
        border: 0,
        width: '100%',
      }}
    >
      <AlertContent fr={alert.fr} en={alert.en} />
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Actions
          actions={['update', 'delete']}
          entity={QuickAddType.alert}
          entityId={alert.id}
        />
      </div>
    </Card>
  ) : (
    <></>
  )
}

export type AlertAlertType = { Alert: typeof AlertAlert }
