import { SBModel } from '../utils/helpers/CRUDHelper'

export interface Alert extends SBModel {
  fr: AlertLocalizable
  en: AlertLocalizable
  poi?: number
  type: 'info' | 'secondary' | 'success' | 'warning' | 'danger'
}

export interface AlertLocalizable {
  title: string
  content: string
}

export const alertTypes = {
  info: '#009EE5',
  secondary: '#473BD8',
  success: '#50B53E',
  warning: '#FF7600',
  danger: '#FF0000',
}

export const alertOptions = [
  { value: 'info', label: 'Info' },
  { value: 'secondary', label: 'Secondary' },
  { value: 'success', label: 'Success' },
  { value: 'warning', label: 'Warning' },
  { value: 'danger', label: 'Danger' },
]
