import { loginRequest, graphConfig } from '../authConfig'
import { msalInstance } from '../index'

export async function callMsGraphTokenUserInfos() {
  // Throw error if no account
  const account = msalInstance.getActiveAccount()
  if (!account) {
    throw Error(
      'No active account! Verify a user has been signed in and setActiveAccount has been called.'
    )
  }

  // Grab a token from msalInstance
  const response = await msalInstance.acquireTokenSilent({
    ...loginRequest,
    account: account,
  })

  // Add headers to request
  const headers = new Headers()
  headers.append('Authorization', `Bearer ${response.accessToken}`)

  // Setup request option
  const options = {
    method: 'GET',
    headers: headers,
  }

  // Start request
  return fetch(graphConfig.graphMeEndpoint, options)
    .then((response) => response.json())
    .catch((error) => console.log(error))
}
