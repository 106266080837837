import { Tag as ANTDTag, Typography } from 'antd'
import { Tag } from '../../models/Tag'
import { selectTagById } from '../../reducers/TagReducer'
import { useAppSelector } from '../../utils/hooks'
import LocalizedParameter from '../utils/LocalizedParameter'

interface Props {
  id?: number
  model?: Tag
}

export const TagTag = ({ id, model }: Props) => {
  const tag = model ? model : useAppSelector(selectTagById(id ?? 0))
  return tag ? (
    <ANTDTag>
      <LocalizedParameter model={tag} property="name" />
    </ANTDTag>
  ) : (
    <></>
  )
}

export type TagTagType = { Tag: typeof TagTag }
