import { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { requiredRule } from '../../../utils/rules'
import { useAppSelector } from '../../../utils/hooks'
import { Form, FormInstance, Input, Result } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { selectSelectedPOI } from '../../../reducers/POIReducer'

interface Props {
  form: FormInstance
}

const Delete = ({ form }: Props) => {
  const intl = useIntl()
  const item = useAppSelector(selectSelectedPOI())

  useEffect(() => {
    form.setFieldsValue(item)
  }, [item])

  return item ? (
    <Form form={form} layout="vertical">
      <Result
        title="Are you sure to delete this POI ?"
        icon={<QuestionCircleOutlined style={{ color: 'orange' }} />}
      />
      <Form.Item
        name="id"
        required
        rules={[requiredRule(intl)]}
        className="d-none"
      >
        <Input type="hidden" />
      </Form.Item>
    </Form>
  ) : (
    <></>
  )
}

export default Delete
