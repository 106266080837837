import { FilePdfOutlined } from '@ant-design/icons'
import { Card, Col, Row } from 'antd'
import { Link } from 'react-router-dom'
import { Actions } from '../../modules/quick-add/Actions'
import { QuickAddType } from '../../modules/quick-add/Models'
import { useAppSelector } from '../../utils/hooks'
import { selectMenuById } from '../../reducers/MenuReducer'
import { Menu, MenuLocalizable } from '../../models/Menu'

interface Props {
  id?: number
  model?: Menu
}

export const MenuCard = ({ id, model }: Props) => {
  const menu = model ? model : useAppSelector(selectMenuById(id ?? 0))

  const link = (menu: MenuLocalizable): string => {
    if (menu.type === 'tel') {
      return 'tel:' + menu.url
    }
    return menu.url
  }

  return menu ? (
    <Card>
      <Row gutter={24}>
        <Col span={4}>
          <FilePdfOutlined style={{ fontSize: 25 }} />
        </Col>
        <Col
          span={16}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 25,
          }}
        >
          <Link to={link(menu.fr)} target="_blank">
            🇫🇷 {menu.fr.label}
          </Link>
          <Link to={link(menu.en)} target="_blank">
            🇬🇧 {menu.en.label}
          </Link>
        </Col>
        <Col span={4} style={{ textAlign: 'end' }}>
          <Actions
            actions={['update', 'delete']}
            entity={QuickAddType.menu}
            entityId={menu.id}
          />
        </Col>
      </Row>
    </Card>
  ) : null
}

export type MenuCardType = { Card: typeof MenuCard }
