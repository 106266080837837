import { useEffect } from 'react'
import dayjs from 'dayjs'
import { useIntl } from 'react-intl'
import { Breadcrumb, Card, Table } from 'antd'
import PageTitle from '../../components/utils/PageTitle'
import { extractSortKeys } from '../../utils/helpers/TableHelper'
import { useAppDispatch, useAppSelector } from '../../utils/hooks'
import { ColumnsType, TablePaginationConfig } from 'antd/es/table'
import { FilterValue, SorterResult } from 'antd/es/table/interface'
import { NewsletterRegistration } from '../../models/NewsletterRegistration'
import {
  getNewsletterRegistrations,
  selectNewsletterRegistrations,
  setNewsletterRegistrationQuery,
} from '../../reducers/NewsletterRegistrationReducer'

const Newsletter = () => {
  const intl = useIntl()
  const dispatch = useAppDispatch()

  const registrations = useAppSelector(selectNewsletterRegistrations())
  const { query, isLoading } = useAppSelector(
    (state) => state.newsletterRegistration
  )
  const { isOpen: quickAddIsOpen } = useAppSelector((state) => state.quickadd)

  const columns: ColumnsType<NewsletterRegistration> = [
    {
      fixed: 'left',
      key: 'email',
      title: intl.formatMessage({ id: 'EMAIL' }),
      render: (record: NewsletterRegistration) => <>{record.email}</>,
    },
    {
      sorter: true,
      key: 'createdAt',
      title: intl.formatMessage({ id: 'DATE' }),
      render: (record: NewsletterRegistration) => (
        <>{dayjs(record.createdAt).format('DD/MM/YYYY HH:mm:ss')}</>
      ),
    },
  ]

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter:
      | SorterResult<NewsletterRegistration>
      | SorterResult<NewsletterRegistration>[]
  ) => {
    const newQuery = {
      ...query,
      pagination,
      filters: { ...query.filters, ...filters },
      ...extractSortKeys(sorter),
    }
    dispatch(setNewsletterRegistrationQuery(newQuery))
    dispatch(getNewsletterRegistrations(newQuery))
  }

  useEffect(() => {
    if (quickAddIsOpen) {
      return
    }
    // Trigger when QuickAdd is closed
    dispatch(getNewsletterRegistrations(query))
  }, [dispatch, quickAddIsOpen])

  return (
    <>
      <Breadcrumb
        style={{ margin: '16px 0' }}
        items={[{ title: 'Newsletter' }]}
      />

      <Card
        bordered={false}
        bodyStyle={{ padding: 0 }}
        title={
          <PageTitle
            title="Newsletter registrations"
            level={5}
            badge={registrations.length}
          />
        }
      >
        <Table
          scroll={{ x: 600 }}
          columns={columns}
          rowKey={(record) => record.id}
          dataSource={registrations}
          pagination={query.pagination}
          loading={isLoading && !registrations.length}
          onChange={handleTableChange}
        />
      </Card>
    </>
  )
}

export default Newsletter
