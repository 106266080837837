import { Menu } from './Menu'
import { useAppSelector } from '../../utils/hooks'
import { selectMenus, selectMenusByIds } from '../../reducers/MenuReducer'
import { Space } from 'antd'
import Loading from '../utils/Loading'

interface Props {
  ids?: [number]
}

export const List = ({ ids }: Props) => {
  const menus = ids
    ? useAppSelector(selectMenusByIds(ids))
    : useAppSelector(selectMenus())

  const { isLoading } = useAppSelector((state) => state.menu)

  return (
    <Space direction={'vertical'} style={{ width: '100%' }}>
      {isLoading && <Loading />}
      {menus.map((menu) => (
        <Menu.Card key={menu.id} model={menu} />
      ))}
    </Space>
  )
}

export type ListType = { List: typeof List }
