import { POI } from '../../models/POI'
import { Col, Row, Tag } from 'antd'

interface Props {
  poi: POI
}

export const ContentOverview = ({ poi }: Props) => {
  const fr = poi.fr
  const en = poi.en

  return (
    <>
      <Row gutter={30}>
        <Col span={12}>Description</Col>
        <Col span={6}>
          <Tag color={getColor(fr.descriptionTitle, fr.descriptionBody)}>
            🇫🇷
          </Tag>
        </Col>
        <Col span={6}>
          <Tag color={getColor(en.descriptionTitle, en.descriptionBody)}>
            🇬🇧
          </Tag>
        </Col>
      </Row>
      <Row gutter={30}>
        <Col span={12}>Schedule</Col>
        <Col span={6}>
          <Tag color={getColor(fr.scheduleTitle, fr.scheduleBody)}>🇫🇷</Tag>
        </Col>
        <Col span={6}>
          <Tag color={getColor(en.scheduleTitle, en.scheduleBody)}>🇬🇧</Tag>
        </Col>
      </Row>
      <Row gutter={30}>
        <Col span={12}>Booking</Col>
        <Col span={6}>
          <Tag color={getColor(fr.bookingTitle, fr.bookingBody)}>🇫🇷</Tag>
        </Col>
        <Col span={6}>
          <Tag color={getColor(en.bookingTitle, en.bookingBody)}>🇬🇧</Tag>
        </Col>
      </Row>
    </>
  )
}

export type ContentOverviewType = { ContentOverview: typeof ContentOverview }

const getColor = (title: string, body: string) => {
  if (title && body) return 'green'
  if (title || body) return 'orange'
  return 'red'
}
