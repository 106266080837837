import {
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
} from '@ant-design/icons'
import { useAppDispatch } from '../../utils/hooks'
import { Button, Dropdown, Space, MenuProps } from 'antd'
import { setEntity } from '../../reducers/QuickAddReducer'
import { MetaData, QuickAddAction, QuickAddType } from './Models'
import { setSelectedPOI } from '../../reducers/POIReducer'
import { setSelectedCategory } from '../../reducers/CategoryReducer'
import { setSelectedTag } from '../../reducers/TagReducer'
import { setSelectedAlert } from '../../reducers/AlertReducer'
import { setSelectedMenu } from '../../reducers/MenuReducer'

interface Props {
  actions: QuickAddAction[]
  entity: QuickAddType
  entityId?: number
  metadata?: MetaData[]
  style?: 'buttons-horizontal' | 'dropdown'
}

export const Actions = ({
  actions,
  entity,
  entityId,
  metadata,
  style = 'buttons-horizontal',
}: Props) => {
  const dispatch = useAppDispatch()

  const methods = {
    [QuickAddType.user]: () => {},
    [QuickAddType.POI]: setSelectedPOI,
    [QuickAddType.category]: setSelectedCategory,
    [QuickAddType.tag]: setSelectedTag,
    [QuickAddType.alert]: setSelectedAlert,
    [QuickAddType.menu]: setSelectedMenu,
  }

  const handleQuickAddAction = (action: QuickAddAction) => {
    if (action !== 'create') {
      // Set selected item
      // Using `as any` to silent TS errors
      dispatch((methods[entity] as any)(entityId!) as any)
    }
    dispatch(
      setEntity({
        entity: entity,
        action: action,
        metaDatas: metadata,
      })
    )
  }

  const items: MenuProps['items'] = [
    {
      key: 'create' as QuickAddAction,
      label: 'Add new',
      onClick: () => handleQuickAddAction('create'),
    },
    {
      key: 'update' as QuickAddAction,
      label: 'Update',
      onClick: () => handleQuickAddAction('update'),
    },
    {
      key: 'delete' as QuickAddAction,
      label: 'Delete',
      onClick: () => handleQuickAddAction('delete'),
    },
  ].filter((item) => actions.includes(item.key))

  return (
    <>
      {style === 'buttons-horizontal' && (
        <Space direction="horizontal">
          {actions.map((action) =>
            action === 'create' ? (
              <Button
                key="create"
                type="primary"
                onClick={() => handleQuickAddAction('create')}
              >
                Add new
              </Button>
            ) : action === 'update' ? (
              <Button
                key="update"
                icon={<EditOutlined />}
                onClick={() => handleQuickAddAction('update')}
              />
            ) : action === 'delete' ? (
              <Button
                danger
                key="delete"
                icon={<DeleteOutlined />}
                onClick={() => handleQuickAddAction('delete')}
              />
            ) : (
              <></>
            )
          )}
        </Space>
      )}
      {style === 'dropdown' && (
        <Dropdown menu={{ items }} placement="bottomRight">
          <Button icon={<EllipsisOutlined />} type={'text'} />
        </Dropdown>
      )}
    </>
  )
}
