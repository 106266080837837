import { Typography } from 'antd'

const { Title, Paragraph } = Typography

const DataPrivacy = () => {
  return (
    <>
      <Title level={2}>Politique de protection des données</Title>
      <>
        <Title level={4}>1. Introduction</Title>
        <Paragraph>Contenu à rédiger</Paragraph>
      </>
    </>
  )
}

export default DataPrivacy
