import React, { useRef } from 'react'
import ReactQuill, { Quill } from 'react-quill'
// @ts-ignore
import quillEmoji from 'quill-emoji'

import 'quill-emoji/dist/quill-emoji.css'
import 'react-quill/dist/quill.snow.css'

const { EmojiBlot, ShortNameEmoji, ToolbarEmoji, TextAreaEmoji } = quillEmoji

Quill.register('modules/emoji', quillEmoji)

Quill.register(
  {
    'formats/emoji': EmojiBlot,
    'modules/emoji-shortname': ShortNameEmoji,
    'modules/emoji-toolbar': ToolbarEmoji,
    'modules/emoji-textarea': TextAreaEmoji,
  },
  true
)

interface OnChangeHandler {
  (e: any): void
}

type Props = {
  value?: string
  placeholder?: string
  onChange?: OnChangeHandler
}

const TextEditor: React.FC<Props> = ({
  value = '',
  placeholder = undefined,
  onChange = () => {},
}) => {
  return (
    <ReactQuill
      theme="snow"
      value={value}
      modules={{
        toolbar: {
          container: [
            [{ header: [1, 2, false] }],
            [{ color: [] }, { background: [] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [
              { list: 'ordered' },
              { list: 'bullet' },
              { indent: '-1' },
              { indent: '+1' },
            ],
            ['link', 'code'],
            ['clean'],
          ],
          handlers: {},
        },
        'emoji-toolbar': true,
        'emoji-textarea': true,
        'emoji-shortname': true,
      }}
      formats={[
        'header',
        'bold',
        'italic',
        'underline',
        'strike',
        'blockquote',
        'list',
        'bullet',
        'indent',
        'link',
        'emoji',
        'code',
        'color',
      ]}
      onChange={onChange}
      placeholder={placeholder}
    />
  )
}

export default TextEditor
