import qs from 'qs'
import axios, { AxiosError } from 'axios'
import { AppDispatch } from '../../reducers/store'
import { TableParams } from '../../models/TableParams'
import { APIResponse, MessageReponse } from '../setupAxios'
import { getUsersSuccess } from '../../reducers/UserReducer'
import { schema, normalize, NormalizedSchema } from 'normalizr'
import { getPOIsSuccess } from '../../reducers/POIReducer'
import { getCategoriesSuccess } from '../../reducers/CategoryReducer'
import { getTagsSuccess } from '../../reducers/TagReducer'
import { getAlertsSuccess } from '../../reducers/AlertReducer'
import { getMenusSuccess } from '../../reducers/MenuReducer'
import { getNewsletterRegistrationsSuccess } from '../../reducers/NewsletterRegistrationReducer'

export const SBAPIFetchPaginatedDispatch =
  <T>(
    endpoint: string,
    params: TableParams,
    schema: schema.Entity | schema.Entity[],
    actions: any,
    startLoading?: any,
    setIds?: any,
    setPagination?: any
  ) =>
  async (dispatch: AppDispatch) => {
    dispatch(startLoading ? startLoading() : actions.startLoading())
    return SBAPIFetchPaginated(endpoint, params)
      .then((data) => {
        const normalizedData = normalize(data.data, schema)
        dispatch(fillStoreWithNormalizedData(normalizedData))
        dispatch(
          setIds
            ? setIds(normalizedData.result)
            : actions.setIds(normalizedData.result)
        )
        dispatch(
          setPagination
            ? setPagination({
                total: data.totalRecords,
                current: data.currentPage,
              })
            : actions.setPagination({
                total: data.totalRecords,
                current: data.currentPage,
              })
        )
        dispatch(actions.stopLoading())
      })
      .catch((reason: { message: string | undefined; error: AxiosError }) => {
        dispatch(actions.hasError(reason.message))
        throw reason // Throw error again to allow execution of others catch()
      })
  }

export const SBAPIFetchPaginated = async <T>(
  endpoint: string,
  params: TableParams
) =>
  await axios
    .get(`${endpoint}?${qs.stringify(params)}`)
    .then((response) => {
      const data = response.data
      return data
    })
    .catch((error: AxiosError) => {
      const data = error.response?.data as APIResponse<MessageReponse>
      const errorMessage = (data && data.data?.message) ?? ''
      throw { message: errorMessage, error: error } // Throw error again to allow execution of others catch()
    })

export const SBAPIFetchDispatch =
  <T>(
    endpoint: string,
    schema: schema.Entity | schema.Entity[],
    actions: any
  ) =>
  async (dispatch: AppDispatch) => {
    dispatch(actions.startLoading())
    return SBAPIFetch(endpoint)
      .then((data) => {
        const normalizedData = normalize(data.data, schema)
        dispatch(fillStoreWithNormalizedData(normalizedData))
        dispatch(actions.stopLoading())
      })
      .catch((reason: { message: string | undefined; error: AxiosError }) => {
        dispatch(actions.hasError(reason.message))
        throw reason // Throw error again to allow execution of others catch()
      })
  }

export const SBAPIFetch = async <T>(endpoint: string, params?: any) =>
  await axios
    .get(params ? `${endpoint}?${qs.stringify(params)}` : endpoint)
    .then((response) => {
      const data = response.data
      return data
    })
    .catch((error: AxiosError) => {
      const data = error.response?.data as APIResponse<MessageReponse>
      const errorMessage = (data && data.data?.message) ?? ''
      throw { message: errorMessage, error: error } // Throw error again to allow execution of others catch()
    })

export const SBAPICreateDispatch =
  <T>(
    model: T,
    endpoint: string,
    schema: schema.Entity | schema.Entity[],
    actions: any
  ) =>
  async (dispatch: AppDispatch) => {
    dispatch(actions.startLoading())
    return SBAPICreate(endpoint, model)
      .then((data) => {
        /**
         * We do not update the store after a creation success
         * Components will trigger index themselves
         */
        dispatch(actions.stopLoading())
      })
      .catch((reason: { message: string | undefined; error: AxiosError }) => {
        dispatch(actions.hasError(reason.message))
        throw reason // Throw error again to allow execution of others catch()
      })
  }

export const SBAPIUpdateDispatch =
  <T>(model: T, endpoint: string, actions: any) =>
  async (dispatch: AppDispatch) => {
    dispatch(actions.startLoading())

    /**
     * The plan is to immediately dispatch the updated item
     * So we don't need to wait the request to complete in order to display the result
     * However in case of error we need to keep a copy to revert the change
     */
    const oldModel = Object.assign({}, model)
    dispatch(actions.updateItemSuccess(model))

    return await axios
      .put(endpoint, model)
      .then((response) => {
        /**
         * We do not process the result here, as we already dispatch earlier
         * Kick back and relax ...
         */
      })
      .catch((error: AxiosError) => {
        const data = error.response?.data as APIResponse<MessageReponse>
        const errorMessage = (data && data.data?.message) ?? ''

        // Revert the model update
        dispatch(actions.updateItemSuccess(oldModel))
        dispatch(actions.hasError(errorMessage))
        throw error // Throw error again to allow execution of others catch()
      })
  }

export const SBAPIDeleteDispatch =
  <T>(model: T, endpoint: string, actions: any) =>
  async (dispatch: AppDispatch) => {
    dispatch(actions.startLoading())
    return await axios
      .delete(endpoint)
      .then(() => {
        dispatch(actions.deleteItemSuccess(model))
      })
      .catch((error: AxiosError) => {
        const data = error.response?.data as APIResponse<MessageReponse>
        const errorMessage = (data && data.data?.message) ?? ''

        dispatch(actions.hasError(errorMessage))
        throw error // Throw error again to allow execution of others catch()
      })
  }

export const SBAPICreate = async (endpoint: string, body: any) =>
  await axios
    .post(endpoint, body)
    .then((response) => {
      const data = response.data
      return data
    })
    .catch((error: AxiosError) => {
      const data = error.response?.data as APIResponse<MessageReponse>
      const errorMessage = (data && data.data?.message) ?? ''
      throw { message: errorMessage, error: error } // Throw error again to allow execution of others catch()
    })

const fillStoreWithNormalizedData =
  (
    normalizedData: NormalizedSchema<
      {
        [key: string]:
          | {
              [key: string]: any
            }
          | undefined
      },
      any
    >
  ) =>
  (dispatch: AppDispatch) => {
    dispatch(getUsersSuccess(normalizedData.entities.user ?? {}))
    dispatch(getPOIsSuccess(normalizedData.entities.POI ?? {}))
    dispatch(getCategoriesSuccess(normalizedData.entities.category ?? {}))
    dispatch(getTagsSuccess(normalizedData.entities.tag ?? {}))
    dispatch(getAlertsSuccess(normalizedData.entities.alert ?? {}))
    dispatch(getMenusSuccess(normalizedData.entities.menu ?? {}))
    dispatch(
      getNewsletterRegistrationsSuccess(
        normalizedData.entities.newsletterRegistration ?? {}
      )
    )

    // Add other entities here ...
  }
