import { Alert } from './Alert'
import { useAppSelector } from '../../utils/hooks'
import { selectAlerts, selectAlertsByIds } from '../../reducers/AlertReducer'
import { Space } from 'antd'
import Loading from '../utils/Loading'

interface Props {
  ids?: [number]
}

export const List = ({ ids }: Props) => {
  const alerts = ids
    ? useAppSelector(selectAlertsByIds(ids))
    : useAppSelector(selectAlerts())

  const { isLoading } = useAppSelector((state) => state.alert)

  return (
    <Space direction={'vertical'} style={{ width: '100%' }}>
      {isLoading && <Loading />}
      {alerts.map((alert) => (
        <Alert.Alert key={alert.id} model={alert} />
      ))}
    </Space>
  )
}

export type ListType = { List: typeof List }
