import {
  requiredRule,
  stringRule,
  urlRule,
  validPhoneNumberRule,
} from '../../utils/rules'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import 'react-phone-number-input/style.css'
import { Col, Row, Select, Form, Input } from 'antd'
import PhoneInputWithCountrySelect from 'react-phone-number-input'
import { MenuLocalizable } from '../../models/Menu'

interface Props {
  lang: 'fr' | 'en'
  initialValue?: MenuLocalizable
}

export const LinkFormItem = ({ lang, initialValue }: Props) => {
  const intl = useIntl()
  const label = lang === 'en' ? '🇬🇧 Link' : '🇫🇷 Link'
  const form = Form.useFormInstance()

  const [type, setType] = useState<'link' | 'tel'>('link')

  useEffect(() => {
    if (!initialValue) {
      return
    }
    // On update, set the initial value
    setType(initialValue.type)
  }, [initialValue])

  return (
    <Row gutter={[8, 8]}>
      <Col span={4}>
        <Form.Item
          name={[lang, 'type']}
          label={'Type'}
          rules={[requiredRule(intl)]}
        >
          <Select
            defaultValue={'link'}
            onChange={(value: 'link' | 'tel') => {
              setType(value)
              form.setFieldValue([lang, 'url'], '')
            }}
          >
            <Select.Option value={'link'}>Link</Select.Option>
            <Select.Option value={'tel'}>Phone</Select.Option>
          </Select>
        </Form.Item>
      </Col>
      <Col span={20}>
        <Form.Item
          name={[lang, 'url']}
          label={label}
          rules={[
            requiredRule(intl),
            stringRule(intl),
            type === 'link' ? urlRule(intl) : validPhoneNumberRule(intl),
          ]}
        >
          {type === 'link' && <Input placeholder="https://..." />}
          {type === 'tel' && (
            <PhoneInputWithCountrySelect
              defaultCountry="FR"
              onChange={() => {}}
            />
          )}
        </Form.Item>
      </Col>
    </Row>
  )
}

export type LinkFormItemType = { LinkFormItem: typeof LinkFormItem }
