import { TableParams } from '../../models/TableParams'

export const initialQuery: TableParams = {
  pagination: { current: 1, pageSize: 20 },
}

export interface SBModel {
  id: number
}

export interface SBLocalizable {
  fr: any
  en: any
}
