import { FC } from 'react'
import { MenuCard, MenuCardType } from './MenuCard'
import { List, ListType } from './List'
import { LinkFormItem, LinkFormItemType } from './LinkFormItem'

export const Menu: FC &
  MenuCardType &
  ListType &
  LinkFormItemType = (): JSX.Element => <></>

Menu.Card = MenuCard
Menu.List = List
Menu.LinkFormItem = LinkFormItem
