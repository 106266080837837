import { Tooltip } from 'antd'

import HTMLReactParser from 'html-react-parser'
import Paragraph from 'antd/es/typography/Paragraph'

interface ContentProps {
  fr: {
    title: string
    content: string
  }
  en: {
    title: string
    content: string
  }
}

const removeHTMLTags = (input: string): string => {
  const regex = /<[^>]+>/g
  return input.replace(regex, '')
}

export const AlertContent = ({ fr, en }: ContentProps) => {
  return (
    <>
      <div
        style={{
          marginBottom: 8,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 6,
          width: '100%',
        }}
      >
        <div style={{ fontSize: '2em' }}>🇫🇷</div>

        <Tooltip title={removeHTMLTags(fr.title)}>
          <Paragraph
            ellipsis={{ rows: 1, expandable: false }}
            style={{ fontWeight: 'bold', fontSize: '2em', marginBottom: 0 }}
          >
            {HTMLReactParser(fr.title)}
          </Paragraph>
        </Tooltip>
        <Tooltip title={removeHTMLTags(fr.content)}>
          <Paragraph ellipsis={{ rows: 2, expandable: false }}>
            {HTMLReactParser(fr.content)}
          </Paragraph>
        </Tooltip>
      </div>
      <div
        style={{
          marginBottom: 15,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 6,
        }}
      >
        <div style={{ fontSize: '2em' }}>🇬🇧</div>
        <div style={{ fontWeight: 'bold', fontSize: '2em', marginBottom: 0 }}>
          {en.title}
        </div>
        <Tooltip title={removeHTMLTags(en.content)}>
          <Paragraph ellipsis={{ rows: 2, expandable: false }}>
            {HTMLReactParser(en.content)}
          </Paragraph>
        </Tooltip>
      </div>
    </>
  )
}
