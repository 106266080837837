import { useIntl } from 'react-intl'
import { Layout, Menu, theme } from 'antd'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  AlertOutlined,
  HomeOutlined,
  MailOutlined,
  SolutionOutlined,
  TagOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons'
import { useEffect, useState } from 'react'
import { extractSectionFromPath } from '../../../utils/helpers/RouterHelpers'

const { Sider } = Layout

interface Props {}

export const LoggedSider = ({}: Props) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [selectedKey, setSelectedKey] = useState<string>('home')

  const { token } = theme.useToken()

  const contentStyle: React.CSSProperties = {
    color: token.colorTextTertiary,
    backgroundColor: '#fff',
    paddingTop: 10,
  }

  useEffect(() => {
    setSelectedKey(extractSectionFromPath(pathname, 1))
  }, [pathname])

  return (
    <Sider collapsed={true} style={contentStyle}>
      <Menu
        style={contentStyle}
        selectedKeys={[selectedKey]}
        mode="inline"
        items={[
          /*           {
            key: 'home',
            icon: <HomeOutlined />,
            label: intl.formatMessage({ id: 'ADMIN.MENU.HOME' }),
            onClick: () => navigate('/'),
          }, */
          {
            key: 'pois',
            icon: <SolutionOutlined />,
            label: intl.formatMessage({ id: 'ADMIN.MENU.POI' }),
            onClick: () => navigate('/pois'),
          },
          // {
          //   key: 'categories',
          //   icon: <UnorderedListOutlined />,
          //   label: intl.formatMessage({ id: 'ADMIN.MENU.CATEGORIES' }),
          //   onClick: () => navigate('/categories'),
          // },
          {
            key: 'tags',
            icon: <TagOutlined />,
            label: intl.formatMessage({ id: 'ADMIN.MENU.TAGS' }),
            onClick: () => navigate('/tags'),
          },
          {
            key: 'alerts',
            icon: <AlertOutlined />,
            label: intl.formatMessage({ id: 'ADMIN.MENU.ALERTS' }),
            onClick: () => navigate('/alerts'),
          },
          {
            key: 'newsletter',
            icon: <MailOutlined />,
            label: intl.formatMessage({ id: 'ADMIN.MENU.NEWSLETTER' }),
            onClick: () => navigate('/newsletter'),
          },
        ]}
      />
    </Sider>
  )
}
