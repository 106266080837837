import UserReducer from './UserReducer'
import { configureStore } from '@reduxjs/toolkit'
import QuickAddReducer from './QuickAddReducer'
import POIReducer from './POIReducer'
import CategoryReducer from './CategoryReducer'
import TagReducer from './TagReducer'
import AlertReducer from './AlertReducer'
import MenuReducer from './MenuReducer'
import NewsletterRegistrationReducer from './NewsletterRegistrationReducer'

export const store = configureStore({
  reducer: {
    user: UserReducer,
    POI: POIReducer,
    category: CategoryReducer,
    tag: TagReducer,
    alert: AlertReducer,
    menu: MenuReducer,
    newsletterRegistration: NewsletterRegistrationReducer,
    quickadd: QuickAddReducer,
  },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
