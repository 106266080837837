import { useIntl } from 'react-intl'
import PageTitle from '../../components/utils/PageTitle'
import {
  Breadcrumb,
  Card,
  Col,
  Descriptions,
  Divider,
  Row,
  Space,
  Switch,
  Tooltip,
  Typography,
} from 'antd'
import { Actions } from '../../modules/quick-add/Actions'
import { MetaDataKey, QuickAddType } from '../../modules/quick-add/Models'
import { useAppDispatch, useAppSelector } from '../../utils/hooks'
import { useParams } from 'react-router-dom'
import {
  getPOIWithId,
  selectPOIById,
  updateVisibility,
} from '../../reducers/POIReducer'
import { POI as POIModel } from '../../models/POI'
import { Tag } from '../../components/tag/Tag'
import { Category } from '../../components/category/Category'
import LocalizedParameter from '../../components/utils/LocalizedParameter'
import { POI } from '../../components/poi/POI'
import { Alert } from '../../components/Alert/Alert'
import { getAlerts } from '../../reducers/AlertReducer'
import { useEffect } from 'react'
import { initialQuery } from '../../utils/helpers/CRUDHelper'
import { TableParams } from '../../models/TableParams'
import HTMLReactParser from 'html-react-parser'
import { getMenus, selectMenus } from '../../reducers/MenuReducer'
import { Menu } from '../../components/Menu/Menu'

const { Text } = Typography

const POIsDetail = () => {
  const intl = useIntl()
  const dispatch = useAppDispatch()

  const params = useParams()
  const poiId = parseInt(params['poiId']!)

  let poi = useAppSelector(selectPOIById(poiId))

  const { isOpen: quickAddIsOpen } = useAppSelector((state) => state.quickadd)

  // To be reused when fetching menus
  const baseQuery: TableParams = {
    ...initialQuery,
    filters: { pois: [poiId] },
    pagination: { current: 1, pageSize: 10000 },
    // As item will be displayed in list and there won't be a lot
    // We make sure to fetch all of them at once
  }

  useEffect(() => {
    if (quickAddIsOpen) {
      return
    }
    // Trigger when QuickAdd is closed

    dispatch(getPOIWithId(poiId))

    // Fetch POI alerts
    dispatch(getAlerts(baseQuery))
    // Fetch POI menu
    dispatch(getMenus(baseQuery))
  }, [dispatch, quickAddIsOpen])

  const menus = useAppSelector(selectMenus())

  return poi ? (
    <>
      <Breadcrumb
        style={{ margin: '16px 0' }}
        items={[
          { title: 'Home' },
          { title: 'POI list' },
          { title: 'POI details' },
        ]}
      />

      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Card
            bordered={false}
            bodyStyle={{ padding: 0 }}
            title={
              <PageTitle
                title={<LocalizedParameter model={poi} property="name" />}
                level={5}
                toolbar={
                  <Space direction="horizontal">
                    <Tooltip title="POI Visibility">
                      <Switch
                        checked={poi.isVisible}
                        onClick={(checked, e) => {
                          if (poi !== undefined) {
                            const dataPoi: POIModel = { ...poi }
                            dataPoi.isVisible = !dataPoi.isVisible
                            dispatch(updateVisibility(dataPoi))
                          }
                        }}
                      />
                    </Tooltip>
                    <Actions
                      actions={['delete']}
                      entity={QuickAddType.POI}
                      entityId={poiId}
                    />
                  </Space>
                }
              />
            }
          ></Card>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12}>
          <Card
            title={
              <PageTitle
                title="Informations"
                level={5}
                toolbar={
                  <Actions
                    actions={['update']}
                    entity={QuickAddType.POI}
                    entityId={poiId}
                  />
                }
              />
            }
            bordered={false}
          >
            <Space
              direction="vertical"
              style={{ width: '100%', marginBottom: 20 }}
            >
              <Text>Header Picture</Text>
              <POI.Cover id={poi.id} />
            </Space>
            <Space direction="vertical" style={{ width: '100%' }}>
              <Text>Map icon (36px * 36px)</Text>
              <div style={{ width: 137 }}>
                <POI.Marker id={poi.id} />
              </div>
            </Space>
            <Descriptions title="Catégories">
              <Descriptions.Item>
                <Category.Tags ids={poi?.categories} />
              </Descriptions.Item>
            </Descriptions>
            <Divider />
            <Descriptions title="Tags">
              <Descriptions.Item>
                <Tag.Tags ids={poi?.tags} />
              </Descriptions.Item>
            </Descriptions>
            <Divider />
            <Descriptions title="Coordonnées">
              <Descriptions.Item label="Latitude">
                {poi.latitude}
              </Descriptions.Item>
              <Descriptions.Item label="Longitude">
                {poi.longitude}
              </Descriptions.Item>
            </Descriptions>
            <Divider />
            <Descriptions title="🇫🇷" layout="vertical" column={1}>
              <Descriptions.Item label="Nom">{poi.fr.name}</Descriptions.Item>
              <Descriptions.Item label="Titre de la description">
                {poi.fr.descriptionTitle}
              </Descriptions.Item>

              <Descriptions.Item label="Description">
                {poi.fr.descriptionBody
                  ? HTMLReactParser(poi.fr.descriptionBody)
                  : null}
              </Descriptions.Item>

              <Descriptions.Item label="Titre des horaires">
                {poi.fr.scheduleTitle}
              </Descriptions.Item>
              <Descriptions.Item label="Description des horaires">
                {poi.fr.scheduleBody
                  ? HTMLReactParser(poi.fr.scheduleBody)
                  : null}
              </Descriptions.Item>
              <Descriptions.Item label="Titre de la réservation">
                {poi.fr.bookingTitle}
              </Descriptions.Item>

              <Descriptions.Item label="Description de la réservation">
                {poi.fr.bookingBody
                  ? HTMLReactParser(poi.fr.bookingBody)
                  : null}
              </Descriptions.Item>
            </Descriptions>
            <Divider />
            <Descriptions title="🇬🇧" layout="vertical" column={1}>
              <Descriptions.Item label="Name">{poi.en.name}</Descriptions.Item>
              <Descriptions.Item label="Description title">
                {poi.en.descriptionTitle}
              </Descriptions.Item>
              <Descriptions.Item label="Description body">
                {poi.en.descriptionBody
                  ? HTMLReactParser(poi.en.descriptionBody)
                  : null}
              </Descriptions.Item>
              <Descriptions.Item label="Schedule title">
                {poi.en.scheduleTitle}
              </Descriptions.Item>
              <Descriptions.Item label="Schedule body">
                {poi.en.scheduleBody
                  ? HTMLReactParser(poi.en.scheduleBody)
                  : null}
              </Descriptions.Item>
              <Descriptions.Item label="bookingTitle">
                {poi.en.bookingTitle}
              </Descriptions.Item>
              <Descriptions.Item label="Booking body'">
                {poi.en.bookingBody
                  ? HTMLReactParser(poi.en.bookingBody)
                  : null}
              </Descriptions.Item>
            </Descriptions>
          </Card>
        </Col>

        <Col xs={24} sm={24} md={24} lg={12}>
          <Card
            bordered={false}
            style={{ width: '100%', marginBottom: 10 }}
            bodyStyle={{ width: '100%' }}
            title={
              <PageTitle
                title="Alerts"
                level={5}
                toolbar={
                  <Actions
                    actions={['create']}
                    entity={QuickAddType.alert}
                    metadata={[{ key: MetaDataKey.poiId, value: poi.id }]}
                  />
                }
              />
            }
          >
            <Alert.List />
          </Card>
          <Card
            bordered={false}
            style={{ width: '100%' }}
            bodyStyle={{ width: '100%' }}
            title={
              <PageTitle
                title="Menus"
                level={5}
                toolbar={
                  <Actions
                    actions={['create']}
                    entity={QuickAddType.menu}
                    metadata={[{ key: MetaDataKey.poiId, value: poi.id }]}
                  />
                }
              />
            }
          >
            <Menu.List />
          </Card>
        </Col>
      </Row>
    </>
  ) : (
    <></>
  )
}

export default POIsDetail
