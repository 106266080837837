import {
  PublicClientApplication,
  EventType,
  EventMessage,
  AuthenticationResult,
} from '@azure/msal-browser'
import './index.css'
import { ConfigProvider } from 'antd'
import { Provider } from 'react-redux'
import ReactDOM from 'react-dom/client'
import * as Sentry from '@sentry/react'
import { store } from './reducers/store'
import { msalConfig } from './authConfig'
import AppRoutes from './routes/AppRoutes'
import reportWebVitals from './reportWebVitals'
import { MsalProvider } from '@azure/msal-react'
import { I18nProvider } from './i18n/i18nProvider'
import { BrowserRouter as Router } from 'react-router-dom'
import ClientSideNavigation from './utils/ClientSideNavigation'

export const msalInstance = new PublicClientApplication(msalConfig)

// Default to using the first account if no account is active on page load
if (
  !msalInstance.getActiveAccount() &&
  msalInstance.getAllAccounts().length > 0
) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0])
}

// Optional - This will update account state if a user signs in from another tab or window
msalInstance.enableAccountStorageEvents()

msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult
    const account = payload.account
    msalInstance.setActiveAccount(account)
  }
})

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.

  environment: process.env.REACT_APP_ENVIRONMENT,
  release: 'sb-react-sso-base@1.0.0',
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <Router basename="/">
    <ClientSideNavigation pca={msalInstance}>
      <MsalProvider instance={msalInstance}>
        <Provider store={store}>
          <I18nProvider>
            <ConfigProvider theme={{ token: { colorPrimary: '#6c7cff' } }}>
              <AppRoutes></AppRoutes>
            </ConfigProvider>
          </I18nProvider>
        </Provider>
      </MsalProvider>
    </ClientSideNavigation>
  </Router>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
