import { initialQuery } from './CRUDHelper'
import { RootState } from '../../reducers/store'
import { TableParams } from '../../models/TableParams'
import { Key } from 'react'

/**
 * TYPES
 */

interface Action {
  payload: any
  type: any
}

export interface SBState<T> {
  isLoading: boolean
  error: Error | string | null
  query: TableParams
  items: { [key: string]: T }
  ids: number[]
  selectedId: number | undefined
}

export type SBSelectRaw<T> = (state: RootState) => T

/**
 * REDUCERS
 */

export const startLoading = (state: any) => ({
  ...state,
  isLoading: true,
  error: null,
})
export const stopLoading = (state: any) => ({
  ...state,
  isLoading: false,
})
export const hasError = (state: any, action: Action) => ({
  ...state,
  isLoading: false,
  error: action.payload,
})
export const setQuery = (state: any, action: Action) => ({
  ...state,
  query: action.payload,
})
export const setPagination = (state: any, action: Action) => ({
  ...state,
  query: {
    ...state.query,
    pagination: { ...state.query.pagination, ...action.payload },
  },
})
export const setSelectedId = (state: any, action: Action) => ({
  ...state,
  selectedId: action.payload,
})
export const setIds = (state: any, action: Action) => ({
  ...state,
  ids: action.payload,
})
export const resetQueryAndIds = (state: any, action: Action) => ({
  ...state,
  ids: [],
  query: {
    ...initialQuery,
    ...(action.payload ?? {}),
  },
})
export const getItemsSuccess = (state: any, action: Action) => ({
  ...state,
  //   isLoading: false,
  items: { ...state.items, ...action.payload },
})
export const updateItemSuccess = (state: any, action: Action) => {
  const next = { ...state.items }
  next[action.payload.id] = { ...next[action.payload.id], ...action.payload }
  return {
    ...state,
    isLoading: false,
    items: next,
  }
}
export const deleteItemSuccess = (state: any, action: Action) => {
  const next = { ...state.items }
  delete next[action.payload.id]
  return {
    ...state,
    isLoading: false,
    items: next,
    ids: state.ids.filter((i: any) => i.id !== action.payload.id),
  }
}

export const baseReducers = {
  startLoading,
  stopLoading,
  hasError,
  setIds,
  setQuery,
  setPagination,
  setSelectedId,
  resetQueryAndIds,
  getItemsSuccess,
  updateItemSuccess,
  deleteItemSuccess,
}

/**
 * Merge two array of object without creating duplicates
 * In case of value present in both, the value in new the array is kept
 *
 * @param initialData Array<any>
 * @param newData Array<any>
 */
export function mergeObjectArraysWithoutDuplicates(
  initialData: Array<any>,
  newData: Array<any>
) {
  const ids = new Set(newData.map((i) => i.id))
  return [...newData, ...initialData.filter((i) => !ids.has(i.id))]
}

export function mergeNumberArraysWithoutDuplicates(
  initialData: Array<number>,
  newData: Array<number>
) {
  const concat = initialData.concat(newData)
  return concat.filter((item, pos) => concat.indexOf(item) === pos)
}

export function getIdOrModelId<T>(arg: T | number) {
  return Number.isNaN(arg) ? (arg as any).id : arg
}
