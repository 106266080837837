import { useIntl } from 'react-intl'
import { Divider, Form, FormInstance, Input, InputNumber } from 'antd'
import { numberRule, requiredRule, stringRule } from '../../../utils/rules'

interface Props {
  form: FormInstance
}

const Create = ({ form }: Props) => {
  const intl = useIntl()

  return (
    <Form form={form} layout="vertical">
      <Form.Item
        name="priority"
        label="Priority"
        rules={[requiredRule(intl), numberRule(intl)]}
      >
        <InputNumber min={1} step={1} />
      </Form.Item>
      <Form.Item
        name={['fr', 'name']}
        label="🇫🇷 Nom"
        rules={[requiredRule(intl), stringRule(intl)]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={['en', 'name']}
        label="🇬🇧 Name"
        rules={[requiredRule(intl), stringRule(intl)]}
      >
        <Input />
      </Form.Item>
      <Divider />
    </Form>
  )
}

export default Create
