import getColumnSearchProps, {
  DataIndex,
} from '../../components/table/getColumnSearchProps'
import { useIntl } from 'react-intl'
import { Tag } from '../../models/Tag'
import { useEffect, useRef } from 'react'
import PageTitle from '../../components/utils/PageTitle'
import { Breadcrumb, Card, InputRef, Table } from 'antd'
import { Actions } from '../../modules/quick-add/Actions'
import { QuickAddType } from '../../modules/quick-add/Models'
import { extractSortKeys } from '../../utils/helpers/TableHelper'
import { ColumnsType, TablePaginationConfig } from 'antd/es/table'
import { FilterValue, SorterResult } from 'antd/es/table/interface'
import { useAppDispatch, useAppSelector } from '../../utils/hooks'
import { getTags, selectTags, setTagQuery } from '../../reducers/TagReducer'

const Tags = () => {
  const intl = useIntl()
  const dispatch = useAppDispatch()

  const searchInput = useRef<InputRef>(null)

  const tags = useAppSelector(selectTags())
  const { query, isLoading } = useAppSelector((state) => state.tag)
  const { isOpen: quickAddIsOpen } = useAppSelector((state) => state.quickadd)

  const handleSearch = (selectedKeys: string[], dataIndex: DataIndex<Tag>) => {
    const newQuery = {
      ...query,
      ...{ search: { [dataIndex]: selectedKeys[0] } },
    }
    dispatch(setTagQuery(newQuery))
    dispatch(getTags(newQuery))
  }

  const handleSearchReset = (clearFilters: () => void) => {
    clearFilters()
    const newQuery = {
      ...query,
      ...{ search: { dataIndex: null } },
    }
    dispatch(setTagQuery(newQuery))
    dispatch(getTags(newQuery))
  }

  const columns: ColumnsType<Tag> = [
    {
      fixed: 'left',
      key: 'name',
      title: intl.formatMessage({ id: 'ADMIN.TABLE.COLUMNS.NAME' }),
      sorter: true,
      width: 250,
      ...getColumnSearchProps<Tag>(
        'name' as keyof Tag,
        searchInput,
        handleSearch,
        handleSearchReset,
        intl
      ),
      render: (record: Tag) => (
        <>
          🇫🇷 {record.fr.name}
          <br />
          🇬🇧 {record.en.name}
        </>
      ),
    },
    {
      sorter: true,
      key: 'priority',
      title: 'Priority',
      dataIndex: 'priority',
    },
    {
      key: 'actions',
      align: 'right',
      render: (record: Tag) => (
        <Actions
          actions={['update', 'delete']}
          entity={QuickAddType.tag}
          entityId={record.id}
        />
      ),
    },
  ]

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Tag> | SorterResult<Tag>[]
  ) => {
    const newQuery = {
      ...query,
      pagination,
      filters: { ...query.filters, ...filters },
      ...extractSortKeys(sorter),
    }
    dispatch(setTagQuery(newQuery))
    dispatch(getTags(newQuery))
  }

  useEffect(() => {
    if (quickAddIsOpen) {
      return
    }
    // Trigger when QuickAdd is closed
    dispatch(getTags(query))
  }, [dispatch, quickAddIsOpen])

  return (
    <>
      <Breadcrumb
        style={{ margin: '16px 0' }}
        items={[/* { title: 'Home' }, */ { title: 'Tags' }]}
      />

      <Card
        bordered={false}
        bodyStyle={{ padding: 0 }}
        title={
          <PageTitle
            title="Tags"
            level={5}
            badge={tags.length}
            toolbar={<Actions actions={['create']} entity={QuickAddType.tag} />}
          />
        }
      >
        <Table
          scroll={{ x: 600 }}
          columns={columns}
          rowKey={(record) => record.id}
          dataSource={tags}
          pagination={query.pagination}
          loading={isLoading && !tags.length}
          onChange={handleTableChange}
        />
      </Card>
    </>
  )
}

export default Tags
