import {
  SBSelectRaw,
  SBState,
  baseReducers,
  getIdOrModelId,
} from '../utils/helpers/ReducerHelper'
import {
  SBAPICreateDispatch,
  SBAPIDeleteDispatch,
  SBAPIFetchDispatch,
  SBAPIFetchPaginatedDispatch,
  SBAPIUpdateDispatch,
} from '../utils/helpers/SBAPIHelper'
import { TAG_URL } from '../utils/urls'
import { Tag } from '../models/Tag'
import { AppDispatch, RootState } from './store'
import { tagSchema } from '../models/schema'
import { initialQuery } from '../utils/helpers/CRUDHelper'
import { createSelector, createSlice } from '@reduxjs/toolkit'
import { TableParams } from '../models/TableParams'

const initialState: SBState<Tag> = {
  isLoading: false,
  error: null,
  items: {},
  ids: [],
  selectedId: undefined,
  query: initialQuery,
}

const slice = createSlice({
  name: 'tag',
  initialState,
  reducers: baseReducers,
})

// Reducer
export default slice.reducer

export const {
  getItemsSuccess: getTagsSuccess,
  setQuery: setTagQuery,
  resetQueryAndIds: resetTagQueryAndIds,
} = slice.actions

/**
 * Selectors
 */

const selectRawItems: SBSelectRaw<{ [key: string]: Tag }> = (
  state: RootState
) => state[slice.name].items
const selectRawIds: SBSelectRaw<number[]> = (state: RootState) =>
  state[slice.name].ids
const selectRawSelectedId: SBSelectRaw<number | undefined> = (
  state: RootState
) => state[slice.name].selectedId

export const selectTags = () =>
  createSelector(
    [selectRawItems, selectRawIds],
    (items, ids) => ids.map((id) => items[id]).filter((i) => i)
    // Filter allow to return only non-null elements
  )
export const selectAllTags = () =>
  createSelector([selectRawItems], (items) =>
    Object.entries(items).map((i) => i[1])
  )
export const selectSelectedTag = () =>
  createSelector([selectRawItems, selectRawSelectedId], (items, id) =>
    id !== undefined ? items[id] : undefined
  )
export const selectTagById = (id: number) =>
  createSelector([selectRawItems], (items) =>
    items.hasOwnProperty(id) ? items[id] : undefined
  )
export const selectTagsByIds = (ids: number[]) =>
  createSelector([selectRawItems], (items) =>
    ids.filter((id) => items.hasOwnProperty(id)).map((id) => items[id])
  )

/**
 * Actions
 */

export const setSelectedTag =
  (Tag: Tag | number) => async (dispatch: AppDispatch) =>
    dispatch(slice.actions.setSelectedId(getIdOrModelId<Tag>(Tag)))

export const getTags = (params: TableParams) =>
  SBAPIFetchPaginatedDispatch<Tag>(TAG_URL, params, [tagSchema], slice.actions)

export const getTagWithId = (id: number) =>
  SBAPIFetchDispatch<Tag>(`${TAG_URL}/${id}`, tagSchema, slice.actions)

export const createTag = (tag: Tag) =>
  SBAPICreateDispatch<Tag>(tag, TAG_URL, tagSchema, slice.actions)

export const updateTag = (tag: Tag) =>
  SBAPIUpdateDispatch<Tag>(tag, `${TAG_URL}/${tag.id}`, slice.actions)

export const deleteTag = (tag: Tag) =>
  SBAPIDeleteDispatch<Tag>(tag, `${TAG_URL}/${tag.id}`, slice.actions)
