import axios from 'axios'
import { useIntl } from 'react-intl'
import { UploadProps, Upload, Typography } from 'antd'
import { InboxOutlined } from '@ant-design/icons'
import type {
  RcFile as OriRcFile,
  UploadProps as RcUploadProps,
  UploadRequestOption,
  UploadRequestError,
} from 'rc-upload/lib/interface'

const { Dragger } = Upload
const { Text } = Typography

interface Props {
  action: string
  accept?: string
  multiple?: boolean
  icon?: React.ReactNode
  title?: string
  onSuccessUpload?: (response: any) => void
}

export const FileUpload = ({
  action,
  accept = '*',
  multiple = false,
  onSuccessUpload,
  title = 'Upload a file',
  icon = <InboxOutlined />,
}: Props) => {
  const intl = useIntl()

  const uploadFile = async (options: UploadRequestOption) => {
    const { onSuccess, onError, file, onProgress } = options

    const data = new FormData()
    data.append('file', file)

    await axios
      .post(action!, data)
      .then((response) => {
        if (onSuccess === undefined) {
          return
        }
        onSuccess('OK')
        onSuccessUpload && onSuccessUpload(response)
      })
      .catch((error) => {
        if (onError === undefined) {
          return
        }
        const err: UploadRequestError = error
        onError(err)
      })
  }

  const uploadProps: UploadProps = {
    action: action,
    maxCount: 1,
    multiple: false,
    accept: accept,
    customRequest: uploadFile,
  }

  return (
    <Dragger {...uploadProps}>
      <p className="ant-upload-drag-icon">{icon}</p>
      {multiple == true ? <Text>Upload some files</Text> : <Text>{title}</Text>}
    </Dragger>
  )
}

export type FileUploadType = { Upload: typeof FileUpload }
