import { Tag } from './Tag'
import { Space } from 'antd'
import { useAppSelector } from '../../utils/hooks'
import { selectTagsByIds } from '../../reducers/TagReducer'

interface Props {
  ids: [number]
}

export const Tags = ({ ids }: Props) => {
  const tags = useAppSelector(selectTagsByIds(ids))
  return tags ? (
    <Space>
      {tags.map((tag) => (
        <Tag.Tag key={tag.id} model={tag} />
      ))}
    </Space>
  ) : (
    <></>
  )
}

export type TagsType = { Tags: typeof Tags }
