import {
  createAlert,
  updateAlert,
  deleteAlert,
} from '../../../reducers/AlertReducer'
import Create from './Create'
import Update from './Update'
import Delete from './Delete'
import { Alert, Form } from 'antd'
import { forwardRef, Ref, useImperativeHandle } from 'react'
import {
  MetaDataKey,
  QuickAddFormInterface,
  QuickAddFormProps,
} from '../Models'

import { useAppDispatch, useAppSelector } from '../../../utils/hooks'

const AlertForm = forwardRef(
  ({}: QuickAddFormProps, ref: Ref<QuickAddFormInterface>) => {
    useImperativeHandle(ref, () => ({
      handleCreate,
      handleUpdate,
      handleDelete,
      handleReset,
    }))

    const dispatch = useAppDispatch()
    const { action, metaDatas } = useAppSelector((state) => state.quickadd)
    const { error } = useAppSelector((state) => state.POI)

    const [createForm] = Form.useForm()
    const [updateForm] = Form.useForm()
    const [deleteForm] = Form.useForm()

    const handleCreate = async () =>
      await createForm.validateFields().then(async (result) =>
        dispatch(createAlert(createForm.getFieldsValue()))
          .then(() => {
            createForm.resetFields()
            return true
          })
          .catch((e: any) => {
            throw 'not lol'
          })
      )

    const handleUpdate = async () =>
      await updateForm.validateFields().then(async (result) =>
        dispatch(updateAlert(updateForm.getFieldsValue()))
          .then(() => {
            updateForm.resetFields()
            return true
          })
          .catch((e: any) => {
            throw 'not lol'
          })
      )

    const handleDelete = async () =>
      await deleteForm.validateFields().then(async (result) =>
        dispatch(deleteAlert(deleteForm.getFieldsValue()))
          .then(() => {
            deleteForm.resetFields()
            return true
          })
          .catch((e: any) => {
            throw 'not lol'
          })
      )

    const handleReset = () => {
      createForm.resetFields()
      updateForm.resetFields()
      deleteForm.resetFields()
    }

    return (
      <>
        {action === 'create' && (
          <Create
            form={createForm}
            poiId={metaDatas?.find((m) => m.key === MetaDataKey.poiId)?.value}
          />
        )}
        {action === 'update' && <Update form={updateForm} />}
        {action === 'delete' && <Delete form={deleteForm} />}
        {error && <Alert message={String(error)} type="error" />}
      </>
    )
  }
)

AlertForm.displayName = 'AlertForm'

export default AlertForm
