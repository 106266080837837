import { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { QuickAddType } from '../Models'
import { alertOptions } from '../../../models/Alert'
import { useAppSelector } from '../../../utils/hooks'
import TextEditor from '../../../components/utils/TextEditor'
import { requiredRule, stringRule } from '../../../utils/rules'
import { Divider, Form, FormInstance, Input, Select } from 'antd'
import { SBAsyncSelect } from '../../../components/select/SBAsyncSelect'

interface Props {
  form: FormInstance
  poiId?: number
}

const Create = ({ form, poiId }: Props) => {
  const intl = useIntl()

  const { isOpen: quickAddIsOpen } = useAppSelector((state) => state.quickadd)

  useEffect(() => {
    if (!quickAddIsOpen) {
      // We want to trigger only the reset
      // when the QuickAdd opens
      return
    }

    form.setFieldValue('poi', poiId)
  }, [quickAddIsOpen])

  return (
    <Form form={form} layout="vertical">
      {!poiId && (
        <SBAsyncSelect
          type={QuickAddType.POI}
          name={'poi'}
          label={'POI'}
          rules={[requiredRule(intl)]}
        />
      )}
      {poiId && (
        <Form.Item
          name="poi"
          style={{ display: 'none' }}
          rules={[requiredRule(intl)]}
        >
          <Input type="hidden" />
        </Form.Item>
      )}
      <Form.Item
        name="type"
        label={'Type'}
        rules={[requiredRule(intl), stringRule(intl)]}
      >
        <Select style={{ width: 120 }} options={alertOptions} />
      </Form.Item>
      <Divider />
      <Form.Item
        name={['fr', 'title']}
        label={'🇫🇷 Titre'}
        rules={[stringRule(intl)]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={['fr', 'content']}
        label={'🇫🇷 Contenu'}
        rules={[requiredRule(intl), stringRule(intl)]}
      >
        <TextEditor />
      </Form.Item>
      <Divider />
      <Form.Item
        name={['en', 'title']}
        label={'🇬🇧 Title'}
        rules={[stringRule(intl)]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={['en', 'content']}
        label={'🇬🇧 Content'}
        rules={[requiredRule(intl), stringRule(intl)]}
      >
        <TextEditor />
      </Form.Item>
      <Divider />
    </Form>
  )
}

export default Create
