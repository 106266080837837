import { SorterResult } from 'antd/es/table/interface'

const extractSortKeys = <T>(sorter: SorterResult<T> | SorterResult<T>[]) =>
  'columnKey' in sorter &&
  sorter.columnKey !== undefined &&
  'order' in sorter &&
  sorter.order !== undefined
    ? { columnKey: sorter.columnKey, order: sorter.order }
    : {}

export { extractSortKeys }
