import { Card } from 'antd'
import { useState } from 'react'
import { PictureOutlined } from '@ant-design/icons'
import { POI_URL } from '../../utils/urls'
import { getPOIWithId, selectPOIById } from '../../reducers/POIReducer'
import { useAppDispatch, useAppSelector } from '../../utils/hooks'
import { DEFAULT_POI_PICTURE } from '../utils/helpers/ImageHelper'
import { File } from '../file/File'

interface Props {
  id: number
}

export const POICover = ({ id }: Props) => {
  const dispatch = useAppDispatch()

  const poi = useAppSelector(selectPOIById(id))

  const [shouldDisplayDropZone, setShouldDisplayDropZone] =
    useState<boolean>(false)
  const [keepDropZoneAlive, setKeepDropZoneAlive] = useState<boolean>(false)

  const showDropZone = function (e: any) {
    e.preventDefault()
    e.stopPropagation()
    setShouldDisplayDropZone(true)
  }

  const hideDropZone = function (e: any) {
    e.preventDefault()
    e.stopPropagation()
    setShouldDisplayDropZone(false)
  }
  return poi ? (
    <div
      className="d-none-lg"
      style={{ position: 'relative' }}
      onDragOver={showDropZone}
      onDragLeave={hideDropZone}
      onMouseOver={showDropZone}
      onMouseOut={hideDropZone}
      onClick={() => setKeepDropZoneAlive(true)}
    >
      <div
        style={{
          width: '100%',
          height: shouldDisplayDropZone || keepDropZoneAlive ? 'unset' : 134,
          backgroundImage: `url(${
            poi?.headerPicture ? poi!.headerPicture.url : DEFAULT_POI_PICTURE
          })`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          imageRendering: 'crisp-edges',
        }}
      >
        {(shouldDisplayDropZone ||
          keepDropZoneAlive ||
          !poi?.headerPicture) && (
          <Card
            style={{ borderRadius: 0 }}
            bodyStyle={{ padding: 0 }}
            bordered={false}
          >
            <File.Upload
              icon={<PictureOutlined />}
              title="Upload POI picture"
              action={`${POI_URL}/${id}/picture`}
              accept={'image/*'}
              onSuccessUpload={() => {
                setKeepDropZoneAlive(false)
                dispatch(getPOIWithId(id))
              }}
            />
          </Card>
        )}
      </div>
    </div>
  ) : (
    <></>
  )
}

export type POICoverType = { Cover: typeof POICover }
