import { useIntl } from 'react-intl'
import { Divider, Form, FormInstance, Input } from 'antd'
import { requiredRule, stringRule } from '../../../utils/rules'

interface Props {
  form: FormInstance
}

const Create = ({ form }: Props) => {
  const intl = useIntl()

  return (
    <Form form={form} layout="vertical">
      <Form.Item
        name={['fr', 'name']}
        label={'🇫🇷 Nom'}
        rules={[requiredRule(intl), stringRule(intl)]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={['en', 'name']}
        label={'🇬🇧 Name'}
        rules={[requiredRule(intl), stringRule(intl)]}
      >
        <Input />
      </Form.Item>
      <Divider />
    </Form>
  )
}

export default Create
