import { useIntl } from 'react-intl'
import { Divider, Form, FormInstance, Input, InputNumber } from 'antd'
import { requiredRule, stringRule, numberRule } from '../../../utils/rules'
import TextEditor from '../../../components/utils/TextEditor'
import { SBAsyncSelect } from '../../../components/select/SBAsyncSelect'
import { QuickAddType } from '../Models'

interface Props {
  form: FormInstance
}

const Create = ({ form }: Props) => {
  const intl = useIntl()

  return (
    <Form form={form} layout="vertical">
      <Form.Item
        name={'latitude'}
        label={'Latitude'}
        rules={[requiredRule(intl), numberRule(intl)]}
      >
        <InputNumber />
      </Form.Item>
      <Form.Item
        name={'longitude'}
        label={'Longitude'}
        rules={[requiredRule(intl), numberRule(intl)]}
      >
        <InputNumber />
      </Form.Item>
      <SBAsyncSelect
        type={QuickAddType.tag}
        multiple
        name="tags"
        label={'Tags'}
      />
      <SBAsyncSelect
        type={QuickAddType.category}
        multiple
        name="categories"
        label={'Categories'}
      />
      <Divider />
      <Form.Item
        name={['fr', 'name']}
        label={'🇫🇷 Nom'}
        rules={[requiredRule(intl), stringRule(intl)]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={['fr', 'descriptionTitle']}
        label={'🇫🇷 Titre de la description'}
        rules={[stringRule(intl)]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={['fr', 'descriptionBody']}
        label={'🇫🇷 Description'}
        rules={[stringRule(intl)]}
      >
        <TextEditor />
      </Form.Item>
      <Form.Item
        name={['fr', 'scheduleTitle']}
        label={'🇫🇷 Titre des horaires'}
        rules={[stringRule(intl)]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={['fr', 'scheduleBody']}
        label={'🇫🇷 Description des horaires'}
        rules={[stringRule(intl)]}
      >
        <TextEditor />
      </Form.Item>
      <Form.Item
        name={['fr', 'bookingTitle']}
        label={'🇫🇷 Titre de la réservation'}
        rules={[stringRule(intl)]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={['fr', 'bookingBody']}
        label={'🇫🇷 Description de la réservation'}
        rules={[stringRule(intl)]}
      >
        <TextEditor />
      </Form.Item>
      <Divider />
      <Form.Item
        name={['en', 'name']}
        label={'🇬🇧 Name'}
        rules={[requiredRule(intl), stringRule(intl)]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={['en', 'descriptionTitle']}
        label={'🇬🇧 Description title'}
        rules={[stringRule(intl)]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={['en', 'descriptionBody']}
        label={'🇬🇧 Description body'}
        rules={[stringRule(intl)]}
      >
        <TextEditor />
      </Form.Item>
      <Form.Item
        name={['en', 'scheduleTitle']}
        label={'🇬🇧 Schedule title'}
        rules={[stringRule(intl)]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={['en', 'scheduleBody']}
        label={'🇬🇧 Schedule body'}
        rules={[stringRule(intl)]}
      >
        <TextEditor />
      </Form.Item>
      <Form.Item
        name={['en', 'bookingTitle']}
        label={'🇬🇧 Booking title'}
        rules={[stringRule(intl)]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={['en', 'bookingBody']}
        label={'🇬🇧 Booking body'}
        rules={[stringRule(intl)]}
      >
        <TextEditor />
      </Form.Item>
      <Divider />
    </Form>
  )
}

export default Create
