import { FC } from 'react'
import { CategoryTag, CategoryTagType } from './Tag'
import { Tags, TagsType } from './Tags'

export const Category: FC & CategoryTagType & TagsType = (): JSX.Element => (
  <></>
)

Category.Tag = CategoryTag
Category.Tags = Tags
