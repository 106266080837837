import { POI } from '../../models/POI'
import { useAppSelector } from '../../utils/hooks'
import { selectPOIById } from '../../reducers/POIReducer'
import LocalizedParameter from '../utils/LocalizedParameter'

interface Props {
  id?: number
  model?: POI
}

export const Name = ({ id, model }: Props) => {
  const poi = model ? model : useAppSelector(selectPOIById(id ?? 0))

  return <LocalizedParameter model={poi!} property={'name'} />
}

export type NameType = { Name: typeof Name }
