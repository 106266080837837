import { QuickAddType } from './Models'
import { QuickAddAction } from './Models'

const titles = {
  [QuickAddType.user]: {
    ['create' as QuickAddAction]: '',
    ['update' as QuickAddAction]: '',
    ['delete' as QuickAddAction]: '',
  },
  [QuickAddType.POI]: {
    ['create' as QuickAddAction]: 'Create new POI',
    ['update' as QuickAddAction]: 'Edit POI',
    ['delete' as QuickAddAction]: 'Delete POI',
  },
  [QuickAddType.category]: {
    ['create' as QuickAddAction]: 'Create new category',
    ['update' as QuickAddAction]: 'Edit category',
    ['delete' as QuickAddAction]: 'Delete category',
  },
  [QuickAddType.tag]: {
    ['create' as QuickAddAction]: 'Create new tag',
    ['update' as QuickAddAction]: 'Edit tag',
    ['delete' as QuickAddAction]: 'Delete tag',
  },
  [QuickAddType.alert]: {
    ['create' as QuickAddAction]: 'Create new alert',
    ['update' as QuickAddAction]: 'Edit alert',
    ['delete' as QuickAddAction]: 'Delete alert',
  },
  [QuickAddType.menu]: {
    ['create' as QuickAddAction]: 'Create new menu',
    ['update' as QuickAddAction]: 'Edit menu',
    ['delete' as QuickAddAction]: 'Delete menu',
  },
}

export const getQuickAddTitle = (
  entity: QuickAddType | undefined,
  action: QuickAddAction | undefined
): string =>
  entity == undefined || action == undefined ? 'Error' : titles[entity][action]
