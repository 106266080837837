import { schema } from 'normalizr'

const userSchema = new schema.Entity('user')
const POISchema = new schema.Entity('POI')
const categorySchema = new schema.Entity('category')
const tagSchema = new schema.Entity('tag')
const alertSchema = new schema.Entity('alert')
const menuSchema = new schema.Entity('menu')
const newsletterRegistrationSchema = new schema.Entity('newsletterRegistration')

userSchema.define({})
categorySchema.define({})
tagSchema.define({})
alertSchema.define({
  poi: POISchema,
})
POISchema.define({
  alerts: [alertSchema],
  tags: [tagSchema],
  categories: [categorySchema],
  menu: menuSchema,
})
menuSchema.define({})
newsletterRegistrationSchema.define({})

export {
  userSchema,
  POISchema,
  categorySchema,
  tagSchema,
  alertSchema,
  menuSchema,
  newsletterRegistrationSchema,
}
