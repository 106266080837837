import { FC } from 'react'
import { Name, NameType } from './Name'
import { POICover, POICoverType } from './Cover'
import { ContentOverview, ContentOverviewType } from './ContentOverview'
import { POIMarker, POIMarkerType } from './Marker'

export const POI: FC &
  NameType &
  ContentOverviewType &
  POICoverType &
  POIMarkerType = (): JSX.Element => <></>

POI.Name = Name
POI.Cover = POICover
POI.Marker = POIMarker
POI.ContentOverview = ContentOverview
