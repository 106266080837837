import getColumnSearchProps, {
  DataIndex,
} from '../../components/table/getColumnSearchProps'
import { useIntl } from 'react-intl'
import { Tag } from '../../models/Tag'
import { useEffect, useRef } from 'react'
import PageTitle from '../../components/utils/PageTitle'
import { Breadcrumb, Card, InputRef, Table, Tag as TagANTD } from 'antd'
import { Actions } from '../../modules/quick-add/Actions'
import { QuickAddType } from '../../modules/quick-add/Models'
import { extractSortKeys } from '../../utils/helpers/TableHelper'
import { ColumnsType, TablePaginationConfig } from 'antd/es/table'
import { FilterValue, SorterResult } from 'antd/es/table/interface'
import { useAppDispatch, useAppSelector } from '../../utils/hooks'
import { getTags, selectTags, setTagQuery } from '../../reducers/TagReducer'
import {
  getAlerts,
  selectAlerts,
  setAlertQuery,
} from '../../reducers/AlertReducer'
import { Alert, alertTypes } from '../../models/Alert'
import LocalizedParameter from '../../components/utils/LocalizedParameter'
import { POI } from '../../components/poi/POI'

const Alerts = () => {
  const intl = useIntl()
  const dispatch = useAppDispatch()

  const searchInput = useRef<InputRef>(null)

  const alerts = useAppSelector(selectAlerts())
  const { query, isLoading } = useAppSelector((state) => state.alert)
  const { isOpen: quickAddIsOpen } = useAppSelector((state) => state.quickadd)

  const handleSearch = (selectedKeys: string[], dataIndex: DataIndex<Tag>) => {
    const newQuery = {
      ...query,
      ...{ search: { [dataIndex]: selectedKeys[0] } },
    }
    dispatch(setAlertQuery(newQuery))
    dispatch(getAlerts(newQuery))
  }

  const handleSearchReset = (clearFilters: () => void) => {
    clearFilters()
    const newQuery = {
      ...query,
      ...{ search: { dataIndex: null } },
    }
    dispatch(setAlertQuery(newQuery))
    dispatch(getAlerts(newQuery))
  }

  const columns: ColumnsType<Alert> = [
    {
      key: 'poi',
      fixed: 'left',
      title: 'POI',
      render: (record: Alert) => <POI.Name id={record.poi} />,
    },
    {
      key: 'name',
      title: 'Title',
      render: (record: Alert) => (
        <LocalizedParameter model={record} property={'title'} />
      ),
    },
    {
      key: 'type',
      title: 'Type',
      render: (record: Alert) => (
        <TagANTD color={alertTypes[record.type]}>{record.type}</TagANTD>
      ),
    },
    {
      key: 'actions',
      align: 'right',
      render: (record: Alert) => (
        <Actions
          actions={['update', 'delete']}
          entity={QuickAddType.alert}
          entityId={record.id}
        />
      ),
    },
  ]

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Alert> | SorterResult<Alert>[]
  ) => {
    const newQuery = {
      ...query,
      pagination,
      filters: { ...query.filters, ...filters },
      ...extractSortKeys(sorter),
    }
    dispatch(setAlertQuery(newQuery))
    dispatch(getAlerts(newQuery))
  }

  useEffect(() => {
    if (quickAddIsOpen) {
      return
    }
    // Trigger when QuickAdd is closed
    dispatch(getAlerts(query))
  }, [dispatch, quickAddIsOpen])

  return (
    <>
      <Breadcrumb
        style={{ margin: '16px 0' }}
        items={[/* { title: 'Home' }, */ { title: 'Alerts' }]}
      />

      <Card
        bordered={false}
        bodyStyle={{ padding: 0 }}
        title={
          <PageTitle
            title="Alerts"
            level={5}
            badge={alerts.length}
            toolbar={
              <Actions actions={['create']} entity={QuickAddType.alert} />
            }
          />
        }
      >
        <Table
          scroll={{ x: 600 }}
          columns={columns}
          rowKey={(record) => record.id}
          dataSource={alerts}
          pagination={query.pagination}
          loading={isLoading && !alerts.length}
          onChange={handleTableChange}
        />
      </Card>
    </>
  )
}

export default Alerts
