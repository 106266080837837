import { SBLocalizable } from '../../utils/helpers/CRUDHelper'

interface Props<T extends SBLocalizable> {
  model: T
  property: keyof T['fr']
}

const LocalizedParameter = <T extends SBLocalizable>({
  model,
  property,
}: Props<T>) => {
  return model && model.hasOwnProperty('fr') && model.hasOwnProperty('en') ? (
    <>
      🇫🇷 {model.fr[property]}
      <br />
      🇬🇧 {model.en[property]}
    </>
  ) : (
    <></>
  )
}

export default LocalizedParameter
