import { useRef, useState } from 'react'
import { getQuickAddTitle } from './QuickAddTitle'
import { close } from '../../reducers/QuickAddReducer'
import { QuickAddFormInterface, QuickAddType } from './Models'
import { useAppDispatch, useAppSelector } from '../../utils/hooks'
import { Button, Drawer, message, Modal, Space, Typography } from 'antd'
import POIForm from './poi/POIForm'
import CategoryForm from './category/CategoryForm'
import TagForm from './tag/TagForm'
import AlertForm from './alert/AlertForm'
import MenuForm from './menu/MenuForm'

const { Text } = Typography

const QuickAdd = () => {
  const dispatch = useAppDispatch()
  const { isOpen, entity, action, metaDatas } = useAppSelector(
    (state) => state.quickadd
  )

  const [messageApi, contextHolder] = message.useMessage()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const onSave = () => {
    setIsLoading(true)
    refs[entity!].current
      ?.handleCreate()
      .then((result: any) => fireThatSuccess())
      .catch((e: any) => fireThatError())
  }
  const onUpdate = () => {
    setIsLoading(true)
    refs[entity!].current
      ?.handleUpdate()
      .then((result: any) => fireThatSuccess())
      .catch((e: any) => fireThatError())
  }

  const onDelete = () => {
    setIsLoading(true)
    refs[entity!].current
      ?.handleDelete()
      .then((result: any) => fireThatSuccess())
      .catch((e: any) => fireThatError())
  }

  const onClose = () => {
    refs[entity!].current?.handleReset()
    dispatch(close())
  }

  const fireThatSuccess = () => {
    messageApi.open({
      type: 'success',
      content: 'Yeah!',
    })
    setIsLoading(false)
    onClose()
  }

  const fireThatError = () => {
    messageApi.open({
      type: 'error',
      content: 'Oops ...',
    })
    setIsLoading(false)
  }

  const refs = {
    [QuickAddType.user]: useRef<QuickAddFormInterface>(null),
    [QuickAddType.POI]: useRef<QuickAddFormInterface>(null),
    [QuickAddType.category]: useRef<QuickAddFormInterface>(null),
    [QuickAddType.tag]: useRef<QuickAddFormInterface>(null),
    [QuickAddType.alert]: useRef<QuickAddFormInterface>(null),
    [QuickAddType.menu]: useRef<QuickAddFormInterface>(null),
  }
  const forms = {
    [QuickAddType.user]: <></>,
    [QuickAddType.POI]: <POIForm ref={refs[QuickAddType.POI]} />,
    [QuickAddType.category]: <CategoryForm ref={refs[QuickAddType.category]} />,
    [QuickAddType.tag]: <TagForm ref={refs[QuickAddType.tag]} />,
    [QuickAddType.alert]: <AlertForm ref={refs[QuickAddType.alert]} />,
    [QuickAddType.menu]: <MenuForm ref={refs[QuickAddType.menu]} />,
  }

  return (
    <>
      {contextHolder}
      {(action === 'create' || action === 'update' || action === 'view') && (
        <Drawer
          title={getQuickAddTitle(entity, action)}
          placement="right"
          onClose={onClose}
          open={isOpen}
          width={600}
          footer={
            <Space className="p-2 text-right">
              <Button onClick={onClose} disabled={isLoading}>
                Close
              </Button>
              {entity && action === 'create' && (
                <Button onClick={onSave} type="primary" loading={isLoading}>
                  Save
                </Button>
              )}
              {entity && action === 'update' && (
                <Button
                  onClick={onUpdate}
                  type="primary"
                  color="secondary"
                  loading={isLoading}
                >
                  Update
                </Button>
              )}
            </Space>
          }
        >
          {!entity && <Text>Choose what to do first and come back later</Text>}
          {entity && forms[entity]}
        </Drawer>
      )}
      {action === 'delete' && (
        <Modal
          open={isOpen}
          onCancel={onClose}
          footer={
            <Space className="p-2 text-right">
              <Button onClick={onClose} disabled={isLoading}>
                Close
              </Button>
              {entity && action === 'delete' && (
                <Button
                  onClick={onDelete}
                  type="primary"
                  color="danger"
                  loading={isLoading}
                >
                  Delete
                </Button>
              )}
            </Space>
          }
        >
          {!entity && <Text>Choose what to do first and come back later</Text>}
          {entity && forms[entity]}
        </Modal>
      )}
    </>
  )
}

export default QuickAdd
