import { Space } from 'antd'
import { Category } from './Category'
import { useAppSelector } from '../../utils/hooks'
import { selectCategoriesByIds } from '../../reducers/CategoryReducer'

interface Props {
  ids: [number]
}

export const Tags = ({ ids }: Props) => {
  const categories = useAppSelector(selectCategoriesByIds(ids))
  return categories ? (
    <Space>
      {categories.map((category) => (
        <Category.Tag key={category.id} model={category} />
      ))}
    </Space>
  ) : (
    <></>
  )
}

export type TagsType = { Tags: typeof Tags }
