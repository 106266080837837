const BASE_API_URL = process.env.REACT_APP_API_URL
const API_URL = BASE_API_URL + '/v1'

export const POI_URL = API_URL + '/pois'
export const CATEGORY_URL = API_URL + '/categories'
export const TAG_URL = API_URL + '/tags'
export const ALERT_URL = API_URL + '/alerts'
export const MENU_URL = API_URL + '/menus'
export const NEWSLETTER_REGISTRATION_URL = API_URL + '/newsletter-registrations'

const ADMIN_URL = API_URL + '/admins'
export const HOME_URL = ADMIN_URL + '/home'
