import { Form, Radio, Select, Spin } from 'antd'
import { FormItemProps, Rule } from 'antd/es/form'
import { NamePath } from 'antd/es/form/interface'
import { debounce } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import {
  MetaData,
  MetaDataKey,
  QuickAddType,
} from '../../modules/quick-add/Models'
import { SBModel, initialQuery } from '../../utils/helpers/CRUDHelper'
import { CATEGORY_URL, POI_URL, TAG_URL } from '../../utils/urls'
import { TableParams } from '../../models/TableParams'
import { useAppSelector } from '../../utils/hooks'
import { SBAPIFetchPaginated } from '../../utils/helpers/SBAPIHelper'
import { Tag } from '../tag/Tag'
import { Tag as TagModel } from '../../models/Tag'
import { Category } from '../category/Category'
import { Category as CategoryModel } from '../../models/Category'
import LocalizedParameter from '../utils/LocalizedParameter'
import { POI as POIModel } from '../../models/POI'

const { Option } = Select

type typesReturnType<T> = Record<
  QuickAddType,
  {
    endpoint: string
    canbeRadio: boolean
    option: (item: T) => JSX.Element
    extra?: (item: T) => JSX.Element
    footer?: (shouldTriggerIndex: () => void) => JSX.Element
  }
>

export const quickAddTypeSelectInfos: typesReturnType<SBModel> = {
  [QuickAddType.POI]: {
    endpoint: POI_URL,
    canbeRadio: false,
    option: (item: SBModel) => (
      <LocalizedParameter
        model={item as unknown as POIModel}
        property={'name'}
      />
    ),
  },
  [QuickAddType.alert]: {
    endpoint: POI_URL,
    canbeRadio: false,
    option: (item: SBModel) => <></>,
  },
  [QuickAddType.menu]: {
    endpoint: POI_URL,
    canbeRadio: false,
    option: (item: SBModel) => <></>,
  },
  [QuickAddType.tag]: {
    endpoint: TAG_URL,
    canbeRadio: false,
    option: (item: SBModel) => (
      <Tag.Tag model={item as unknown as TagModel}></Tag.Tag>
    ),
  },
  [QuickAddType.category]: {
    endpoint: CATEGORY_URL,
    canbeRadio: false,
    option: (item: SBModel) => (
      <Category.Tag model={item as unknown as CategoryModel}></Category.Tag>
    ),
  },
  [QuickAddType.user]: {
    endpoint: POI_URL,
    canbeRadio: false,
    option: (item: SBModel) => <></>,
  },
}

interface SBAsyncSelectProps {
  type: QuickAddType
  name?: NamePath
  label?: string
  rules?: Rule[]
  multiple?: boolean
  metadata?: MetaData[]
}

export const SBAsyncSelect = <T extends SBModel>({
  type,
  name,
  label,
  rules,
  multiple = false,
  metadata,
  hidden,
}: SBAsyncSelectProps & FormItemProps) => {
  const form = Form.useFormInstance()
  const [items, setItems] = useState<T[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [query, setQuery] = useState<TableParams>(initialQuery)
  const [itemSelected, setItemSelected] = useState<T | undefined>(undefined)

  const { isOpen: quickAddIsOpen } = useAppSelector((state) => state.quickadd)

  const handleSearch = (newValue: string) => {
    let newQuery: TableParams = {
      ...composeQueryFiltered(),
      search: { name: newValue },
    }

    if (!newValue) {
      delete newQuery.search
    }

    setQuery(newQuery)
    fetchDataForQuery(newQuery)
  }
  const debouncedSearch = useMemo(() => debounce(handleSearch, 500), [])

  const handleOnChange = (e: any) => {
    setItemSelected(items.find((i) => i.id === e))
  }

  const composeQueryFiltered = () => {
    var query: TableParams = { ...initialQuery }

    // If needed, filter the query
    if (!metadata) {
      return query
    }

    const keysToWrapInArray = [
      { metadata: MetaDataKey.poiId, key: 'pois', wrapInArray: true },
    ]
    for (const item of keysToWrapInArray) {
      const data = metadata.find((m) => m.key === item.metadata)
      if (data === undefined) {
        continue
      }
      query = {
        ...query,
        filters: {
          [item.key]: item.wrapInArray ? [data.value] : data.value,
        },
      }
    }

    return query
  }

  const fetchDataForQuery = (newQuery: TableParams) => {
    setIsLoading(true)
    SBAPIFetchPaginated<SBModel>(
      quickAddTypeSelectInfos[type].endpoint,
      newQuery
    ).then((data: any) => {
      setItems(data.data)
      setIsLoading(false)
    })
  }

  useEffect(() => {
    if (!quickAddIsOpen) {
      // We want to trigger only the reset
      // when the QuickAdd opens
      return
    }

    setItemSelected(undefined)

    const baseQuery = composeQueryFiltered()
    setQuery(baseQuery)
    fetchDataForQuery(baseQuery)
  }, [quickAddIsOpen, setQuery, metadata])

  useEffect(() => {
    const value = form.getFieldValue(name ?? '')
    if (!Array.isArray(value)) {
      return
    }
    const existingIds = items.map((item) => item.id)
    const missing = value.filter((id) => !existingIds.includes(id))
    fetchDataForQuery({ ...query, ids: missing })
  }, [form.getFieldValue(name ?? '')])

  useEffect(() => {
    return () => {
      debouncedSearch.cancel()
    }
  })

  return (
    <>
      <Form.Item name={name} label={label} rules={rules} hidden={hidden}>
        {isLoading && quickAddTypeSelectInfos[type].canbeRadio ? (
          <Spin />
        ) : quickAddTypeSelectInfos[type].canbeRadio && items.length < 4 ? (
          <Radio.Group>
            {items.map((item) => (
              <Radio.Button key={item.id} value={item.id}>
                {quickAddTypeSelectInfos[type].option(item)}
              </Radio.Button>
            ))}
          </Radio.Group>
        ) : (
          <Select
            size={'large'}
            loading={isLoading}
            filterOption={false}
            showSearch={true}
            showArrow={true}
            onSearch={debouncedSearch}
            onChange={handleOnChange}
            mode={multiple ? 'multiple' : undefined}
            dropdownRender={
              quickAddTypeSelectInfos[type].footer !== undefined
                ? (menu) => (
                    <>
                      {menu}
                      {quickAddTypeSelectInfos[type].footer!(() =>
                        handleSearch('')
                      )}
                    </>
                  )
                : undefined
            }
          >
            {items.map((item) => (
              <Option key={item.id} value={item.id}>
                {quickAddTypeSelectInfos[type].option(item)}
              </Option>
            ))}
          </Select>
        )}
      </Form.Item>
      {itemSelected &&
        quickAddTypeSelectInfos[type].extra !== undefined &&
        quickAddTypeSelectInfos[type].extra!(itemSelected)}
    </>
  )
}
