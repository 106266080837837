import { Tag as ANTDTag, Typography } from 'antd'
import { Category } from '../../models/Category'
import { selectCategorieById } from '../../reducers/CategoryReducer'
import { useAppSelector } from '../../utils/hooks'
import LocalizedParameter from '../utils/LocalizedParameter'

interface Props {
  id?: number
  model?: Category
}

export const CategoryTag = ({ id, model }: Props) => {
  const Category = model ? model : useAppSelector(selectCategorieById(id ?? 0))
  return Category ? (
    <ANTDTag>
      <LocalizedParameter model={Category} property="name" />
    </ANTDTag>
  ) : (
    <></>
  )
}

export type CategoryTagType = { Tag: typeof CategoryTag }
