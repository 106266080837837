import getColumnSearchProps, {
  DataIndex,
} from '../../components/table/getColumnSearchProps'
import {
  getPOIs,
  selectPOIs,
  setPOIQuery,
  updateVisibility,
} from '../../reducers/POIReducer'
import { useIntl } from 'react-intl'
import { useEffect, useRef } from 'react'
import { POI as POIModel } from '../../models/POI'
import PageTitle from '../../components/utils/PageTitle'
import {
  Breadcrumb,
  Card,
  InputRef,
  Table,
  Switch,
  Tag as ANTDTag,
  Badge,
} from 'antd'
import { Actions } from '../../modules/quick-add/Actions'
import { QuickAddType } from '../../modules/quick-add/Models'
import { extractSortKeys } from '../../utils/helpers/TableHelper'
import { ColumnsType, TablePaginationConfig } from 'antd/es/table'
import { FilterValue, SorterResult } from 'antd/es/table/interface'
import { useAppDispatch, useAppSelector } from '../../utils/hooks'
import { Link } from 'react-router-dom'
import LocalizedParameter from '../../components/utils/LocalizedParameter'
import { Tag } from '../../components/tag/Tag'
import { Category } from '../../components/category/Category'
import { POI } from '../../components/poi/POI'

const POIList = () => {
  const intl = useIntl()
  const dispatch = useAppDispatch()

  const searchInput = useRef<InputRef>(null)

  const pois = useAppSelector(selectPOIs())
  const { query, isLoading } = useAppSelector((state) => state.POI)
  const { isOpen: quickAddIsOpen } = useAppSelector((state) => state.quickadd)

  const handleSearch = (
    selectedKeys: string[],
    dataIndex: DataIndex<POIModel>
  ) => {
    const newQuery = {
      ...query,
      ...{ search: { [dataIndex]: selectedKeys[0] } },
    }
    dispatch(setPOIQuery(newQuery))
    dispatch(getPOIs(newQuery))
  }

  const handleSearchReset = (clearFilters: () => void) => {
    clearFilters()
    const newQuery = {
      ...query,
      ...{ search: { dataIndex: null } },
    }
    dispatch(setPOIQuery(newQuery))
    dispatch(getPOIs(newQuery))
  }

  const columns: ColumnsType<POIModel> = [
    {
      fixed: 'left',
      key: 'name',
      title: intl.formatMessage({ id: 'ADMIN.TABLE.COLUMNS.NAME' }),
      sorter: true,
      ...getColumnSearchProps<POIModel>(
        'name' as keyof POIModel,
        searchInput,
        handleSearch,
        handleSearchReset,
        intl
      ),
      render: (record: POIModel) => (
        <Link to={`/pois/${record.id}`}>
          <LocalizedParameter model={record} property="name" />
        </Link>
      ),
    },
    {
      key: 'visibility',
      title: 'Visibility',
      render: (record: POIModel) => (
        <Switch
          checked={record.isVisible}
          onClick={() =>
            dispatch(
              updateVisibility({ ...record, isVisible: !record.isVisible })
            )
          }
        />
      ),
    },
    {
      key: 'content',
      title: 'Content',
      width: 210,
      render: (record: POIModel) => <POI.ContentOverview poi={record} />,
    },
    {
      key: 'category',
      title: 'Category',
      render: (record: POIModel) => <Category.Tags ids={record.categories} />,
    },
    {
      key: 'tag',
      title: 'Tag',
      render: (record: POIModel) => <Tag.Tags ids={record.tags} />,
    },
    {
      key: 'alert',
      title: 'Alert',
      render: (record: POIModel) => (
        <Badge count={record.alertsCount ?? 0} showZero color="blue" />
      ),
    },
    {
      key: 'actions',
      align: 'right',
      render: (record: POIModel) => (
        <Actions
          actions={['update', 'delete']}
          entity={QuickAddType.POI}
          entityId={record.id}
        />
      ),
    },
  ]

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<POIModel> | SorterResult<POIModel>[]
  ) => {
    const newQuery = {
      ...query,
      pagination,
      filters: { ...query.filters, ...filters },
      ...extractSortKeys(sorter),
    }
    dispatch(setPOIQuery(newQuery))
    dispatch(getPOIs(newQuery))
  }

  useEffect(() => {
    if (quickAddIsOpen) {
      return
    }
    // Trigger when QuickAdd is closed
    dispatch(getPOIs(query))
  }, [dispatch, quickAddIsOpen])
  return (
    <>
      <Breadcrumb
        style={{ margin: '16px 0' }}
        items={[
          /* { title: 'Home' }, */ { title: 'POI list - Point of Interest' },
        ]}
      />

      <Card
        bordered={false}
        bodyStyle={{ padding: 0 }}
        title={
          <PageTitle
            title="POI - Point of Interest "
            level={5}
            badge={pois.length}
            toolbar={<Actions actions={['create']} entity={QuickAddType.POI} />}
          />
        }
      >
        <Table
          scroll={{ x: 600 }}
          columns={columns}
          rowKey={(record) => record.id}
          dataSource={pois}
          pagination={query.pagination}
          loading={isLoading && !pois.length}
          onChange={handleTableChange}
        />
      </Card>
    </>
  )
}

export default POIList
