import { Layout, theme } from 'antd'
import RightHeader from './logged/RightHeader'
import { LoggedSider } from './logged/LoggedSider'
import { FooterLayout } from './FooterLayout'
import { loginRequest } from '../../authConfig'
import { InteractionType } from '@azure/msal-browser'
import { Outlet, useNavigate } from 'react-router-dom'
import { useMsalAuthentication } from '@azure/msal-react'
import QuickAdd from '../../modules/quick-add/QuickAdd'

const { Header, Content } = Layout

const LoggedLayout = () => {
  const navigate = useNavigate()
  const { token } = theme.useToken()

  // Hook used to force login in case no user is logged-in
  const { result, error } = useMsalAuthentication(
    InteractionType.Redirect,
    loginRequest
  )

  const contentStyle: React.CSSProperties = {
    color: token.colorTextTertiary,
  }

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header style={{ paddingLeft: 20, paddingRight: 20, background: '#fff' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            margin: 0,
            height: '100%',
            alignItems: 'center',
          }}
        >
          <img
            alt="Supplément Bacon"
            src="/assets/logo.png"
            style={{
              float: 'left',
              height: 46,
              cursor: 'pointer',
            }}
            onClick={() => navigate('/')}
          />

          <RightHeader />
        </div>
      </Header>

      <Layout>
        <LoggedSider />
        <Layout style={{ padding: '0 24px 24px', background: '#f8f8f2' }}>
          <Content style={contentStyle}>
            <Outlet />
          </Content>
          <QuickAdd />
          <FooterLayout />
        </Layout>
      </Layout>
    </Layout>
  )
}

export default LoggedLayout
