import {
  SBSelectRaw,
  SBState,
  baseReducers,
  getIdOrModelId,
} from '../utils/helpers/ReducerHelper'
import {
  SBAPICreateDispatch,
  SBAPIDeleteDispatch,
  SBAPIFetchDispatch,
  SBAPIFetchPaginatedDispatch,
  SBAPIUpdateDispatch,
} from '../utils/helpers/SBAPIHelper'
import { CATEGORY_URL } from '../utils/urls'
import { Category } from '../models/Category'
import { AppDispatch, RootState } from './store'
import { categorySchema } from '../models/schema'
import { initialQuery } from '../utils/helpers/CRUDHelper'
import { createSelector, createSlice } from '@reduxjs/toolkit'
import { TableParams } from '../models/TableParams'

const initialState: SBState<Category> = {
  isLoading: false,
  error: null,
  items: {},
  ids: [],
  selectedId: undefined,
  query: initialQuery,
}

const slice = createSlice({
  name: 'category',
  initialState,
  reducers: baseReducers,
})

// Reducer
export default slice.reducer

export const {
  getItemsSuccess: getCategoriesSuccess,
  setQuery: setCategorieQuery,
  resetQueryAndIds: resetCategorieQueryAndIds,
} = slice.actions

/**
 * Selectors
 */

const selectRawItems: SBSelectRaw<{ [key: string]: Category }> = (
  state: RootState
) => state[slice.name].items
const selectRawIds: SBSelectRaw<number[]> = (state: RootState) =>
  state[slice.name].ids
const selectRawSelectedId: SBSelectRaw<number | undefined> = (
  state: RootState
) => state[slice.name].selectedId

export const selectCategories = () =>
  createSelector(
    [selectRawItems, selectRawIds],
    (items, ids) => ids.map((id) => items[id]).filter((i) => i)
    // Filter allow to return only non-null elements
  )
export const selectAllCategories = () =>
  createSelector([selectRawItems], (items) =>
    Object.entries(items).map((i) => i[1])
  )
export const selectSelectedCategorie = () =>
  createSelector([selectRawItems, selectRawSelectedId], (items, id) =>
    id !== undefined ? items[id] : undefined
  )
export const selectCategorieById = (id: number) =>
  createSelector([selectRawItems], (items) =>
    items.hasOwnProperty(id) ? items[id] : undefined
  )
export const selectCategoriesByIds = (ids: number[]) =>
  createSelector([selectRawItems], (items) =>
    ids.filter((id) => items.hasOwnProperty(id)).map((id) => items[id])
  )

/**
 * Actions
 */

export const setSelectedCategory =
  (Categorie: Category | number) => async (dispatch: AppDispatch) =>
    dispatch(slice.actions.setSelectedId(getIdOrModelId<Category>(Categorie)))

export const getCategories = (params: TableParams) =>
  SBAPIFetchPaginatedDispatch<Category>(
    CATEGORY_URL,
    params,
    [categorySchema],
    slice.actions
  )

export const getCategorieWithId = (id: number) =>
  SBAPIFetchDispatch<Category>(
    `${CATEGORY_URL}/${id}`,
    categorySchema,
    slice.actions
  )

export const createCategorie = (category: Category) =>
  SBAPICreateDispatch<Category>(
    category,
    CATEGORY_URL,
    categorySchema,
    slice.actions
  )

export const updateCategorie = (category: Category) =>
  SBAPIUpdateDispatch<Category>(
    category,
    `${CATEGORY_URL}/${category.id}`,
    slice.actions
  )

export const deleteCategorie = (category: Category) =>
  SBAPIDeleteDispatch<Category>(
    category,
    `${CATEGORY_URL}/${category.id}`,
    slice.actions
  )
