import App from '../App'
import { FC } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'

// Auth
import AuthLayout from '../components/layout/auth/AuthLayout'
import Login from '../pages/auth/Login'
import LoginRedirect from '../pages/auth/LoginRedirect'

// Admin
import LoggedLayout from '../components/layout/LoggedLayout'

// Public
import LegalNotice from '../pages/legal/LegalNotice'
import DataPrivacy from '../pages/legal/DataPrivacy'
import POIList from '../pages/admin/POIList'
import POIsDetail from '../pages/admin/POIsDetail'
import Categories from '../pages/admin/Categories'
import Tags from '../pages/admin/Tags'
import Alerts from '../pages/admin/Alerts'
import Newsletter from '../pages/admin/Newsletter'

const AppRoutes: FC = () => (
  <Routes>
    <Route element={<App />}>
      {/* Authentication routes */}
      <Route element={<AuthLayout />}>
        {/* MSAL Login redirect route */}
        <Route path="/login-redirect" element={<LoginRedirect />} />
        {/* Login page */}
        <Route path="/login" element={<Login />} />
      </Route>

      {/* Logged routes - Protected by SSO */}
      <Route element={<LoggedLayout />}>
        <Route path="/" element={<Navigate to="/pois" />} />
        <Route path="/pois" element={<POIList />} />
        <Route path="/pois/:poiId" element={<POIsDetail />} />
        <Route path="/tags" element={<Tags />} />
        <Route path="/alerts" element={<Alerts />} />
        <Route path="/categories" element={<Categories />} />
        <Route path="/newsletter" element={<Newsletter />} />
        {/* Legal documents */}
        <Route path="/legal-notice" element={<LegalNotice />} />
        <Route path="/data-privacy" element={<DataPrivacy />} />
      </Route>

      {/* Fallback route */}
      <Route path="*" element={<Navigate to="/" />} />
    </Route>
  </Routes>
)

export default AppRoutes
