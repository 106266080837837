import { useState } from 'react'
import { useIntl } from 'react-intl'
import { useMsal } from '@azure/msal-react'
import { loginRequest } from '../../authConfig'
import { Button, Space, Typography } from 'antd'

const { Title } = Typography

const Login = () => {
  const { instance } = useMsal()
  const intl = useIntl()
  const [anchorEl, setAnchorEl] = useState(null)

  const handleLogin = (loginType: string) => {
    setAnchorEl(null)

    if (loginType === 'popup') {
      instance.loginPopup(loginRequest)
    } else if (loginType === 'redirect') {
      instance.loginRedirect(loginRequest)
    }
  }

  return (
    <Space
      direction="vertical"
      style={{ padding: 20, textAlign: 'center' }}
      size={20}
    >
      <img src="assets/linear_logo.png" width={300} />
      <Title style={{ margin: 0, marginBottom: '1rem' }}>
        {intl.formatMessage({ id: 'LOGIN.WELCOME_MESSAGE_TITLE' })}
      </Title>
      <Button
        style={{ background: '#293348', color: '#fff' }}
        size="large"
        onClick={() => handleLogin('redirect')}
      >
        {intl.formatMessage({ id: 'LOGIN.BUTTON_TEXT' })}
      </Button>
    </Space>
  )
}

export default Login
