import {
  SBSelectRaw,
  SBState,
  baseReducers,
  getIdOrModelId,
} from '../utils/helpers/ReducerHelper'
import {
  SBAPICreateDispatch,
  SBAPIDeleteDispatch,
  SBAPIFetchDispatch,
  SBAPIFetchPaginatedDispatch,
  SBAPIUpdateDispatch,
} from '../utils/helpers/SBAPIHelper'
import { NEWSLETTER_REGISTRATION_URL } from '../utils/urls'
import { NewsletterRegistration } from '../models/NewsletterRegistration'
import { AppDispatch, RootState } from './store'
import { newsletterRegistrationSchema } from '../models/schema'
import { initialQuery } from '../utils/helpers/CRUDHelper'
import { createSelector, createSlice } from '@reduxjs/toolkit'
import { TableParams } from '../models/TableParams'

const initialState: SBState<NewsletterRegistration> = {
  isLoading: false,
  error: null,
  items: {},
  ids: [],
  selectedId: undefined,
  query: initialQuery,
}

const slice = createSlice({
  name: 'newsletterRegistration',
  initialState,
  reducers: baseReducers,
})

// Reducer
export default slice.reducer

export const {
  getItemsSuccess: getNewsletterRegistrationsSuccess,
  setQuery: setNewsletterRegistrationQuery,
  resetQueryAndIds: resetNewsletterRegistrationQueryAndIds,
} = slice.actions

/**
 * Selectors
 */

const selectRawItems: SBSelectRaw<{ [key: string]: NewsletterRegistration }> = (
  state: RootState
) => state[slice.name].items
const selectRawIds: SBSelectRaw<number[]> = (state: RootState) =>
  state[slice.name].ids
const selectRawSelectedId: SBSelectRaw<number | undefined> = (
  state: RootState
) => state[slice.name].selectedId

export const selectNewsletterRegistrations = () =>
  createSelector(
    [selectRawItems, selectRawIds],
    (items, ids) => ids.map((id) => items[id]).filter((i) => i)
    // Filter allow to return only non-null elements
  )
export const selectAllNewsletterRegistrations = () =>
  createSelector([selectRawItems], (items) =>
    Object.entries(items).map((i) => i[1])
  )
export const selectSelectedNewsletterRegistration = () =>
  createSelector([selectRawItems, selectRawSelectedId], (items, id) =>
    id !== undefined ? items[id] : undefined
  )
export const selectNewsletterRegistrationById = (id: number) =>
  createSelector([selectRawItems], (items) =>
    items.hasOwnProperty(id) ? items[id] : undefined
  )
export const selectNewsletterRegistrationsByIds = (ids: number[]) =>
  createSelector([selectRawItems], (items) =>
    ids.filter((id) => items.hasOwnProperty(id)).map((id) => items[id])
  )

/**
 * Actions
 */

export const setSelectedNewsletterRegistration =
  (NewsletterRegistration: NewsletterRegistration | number) =>
  async (dispatch: AppDispatch) =>
    dispatch(
      slice.actions.setSelectedId(
        getIdOrModelId<NewsletterRegistration>(NewsletterRegistration)
      )
    )

export const getNewsletterRegistrations = (params: TableParams) =>
  SBAPIFetchPaginatedDispatch<NewsletterRegistration>(
    NEWSLETTER_REGISTRATION_URL,
    params,
    [newsletterRegistrationSchema],
    slice.actions
  )
