import { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Divider, Form, FormInstance, Input } from 'antd'
import { useAppSelector } from '../../../utils/hooks'
import { requiredRule, stringRule } from '../../../utils/rules'
import { selectSelectedCategorie } from '../../../reducers/CategoryReducer'

interface Props {
  form: FormInstance
}

const Update = ({ form }: Props) => {
  const intl = useIntl()
  const item = useAppSelector(selectSelectedCategorie())

  useEffect(() => {
    form.setFieldsValue(item)
  }, [item])

  return (
    <Form form={form} layout="vertical">
      <Form.Item
        name="id"
        style={{ display: 'none' }}
        rules={[requiredRule(intl)]}
      >
        <Input type="hidden" />
      </Form.Item>
      <Form.Item
        name={['fr', 'name']}
        label={'🇫🇷 Nom'}
        rules={[requiredRule(intl), stringRule(intl)]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={['en', 'name']}
        label={'🇬🇧 Name'}
        rules={[requiredRule(intl), stringRule(intl)]}
      >
        <Input />
      </Form.Item>
      <Divider />
    </Form>
  )
}

export default Update
